import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import { ArrowDownIcon, ArrowSmallDownIcon } from "../../../assets";
import { Charts, TagStatsItem } from "../../../components";
// import { IMainStats } from "../../../models";
import {
  getChartsStatistics,
  getMainStats,
} from "../../../services/statistics.service";
import styles from "./styles.module.scss";

const Statistics: FC = (): JSX.Element => {
  const [isLoadingMainStats, setIsLoadingMainStats] = useState<boolean>(false);

  const [mainStats, setMainStats] = useState<any>();

  const onGetMainStats = async () => {
    try {
      setIsLoadingMainStats(true);
      const { data } = await getMainStats();
      if (data?.success) {
        setMainStats(data?.data);
      }
      setIsLoadingMainStats(false);
    } catch (error) {
      setIsLoadingMainStats(false);
    }
  };

  useEffect(() => {
    onGetMainStats();
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <h4 className={styles.title}>Statistics</h4>
        </header>

        <section className={styles.contentContainer}>
          {isLoadingMainStats ? (
            <div className={styles.mainLoader}>
              <PulseLoader color={"#7D4AFB"} />
            </div>
          ) : (
            <>
              <div className={styles.chartsContainer}>
                <div className={styles.statisticsContainer}>
                  <div className={styles.allUsers}>
                    <p className={styles.largeRegular}>All Users</p>
                    <p className={styles.h2}>{mainStats?.users?.all}</p>
                  </div>
                  <div className={styles.todayBlock}>
                    <p className={styles.xSmallRegular}>Today</p>
                    <div className={styles.percentWrapper}>
                      <h4>{mainStats?.users?.today}</h4>

                      {mainStats?.users?.percent !== null &&
                        mainStats?.users?.percent !== 0 && (
                          <>
                            <div
                              className={clsx(styles.percentContainer, {
                                [styles.percentContainerNegative]:
                                  mainStats && mainStats?.users.percent < 0,
                              })}
                            >
                              <ArrowSmallDownIcon />
                            </div>
                            <p
                              className={clsx(styles.percentTitle, {
                                [styles.percentTitleNegative]:
                                  mainStats && mainStats?.users.percent < 0,
                              })}
                            >
                              {mainStats?.users?.percent}%
                            </p>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div className={styles.chart}>
                  <Charts />
                </div>
              </div>
              <div className={styles.infoContainer}>
                <div className={styles.infoBlock}>
                  <div className={styles.userBlock}>
                    <p className={styles.largeRegular}>Posts</p>
                    <p className={styles.h5}>0</p>
                  </div>
                  <div className={styles.todayBlock}>
                    <p className={styles.xSmallRegular}>Today</p>

                    <div className={styles.percentWrapper}>
                      <p className={styles.largeBold}>0</p>

                      {/* {mainStats?.candidates?.percent !== null &&
                        mainStats?.candidates?.percent !== 0 && (
                          <>
                            <div
                              className={clsx(styles.percentContainer, {
                                [styles.percentContainerNegative]:
                                  mainStats &&
                                  mainStats?.candidates?.percent < 0,
                              })}
                            >
                              <ArrowSmallDownIcon />
                            </div>
                            <p
                              className={clsx(styles.percentTitle, {
                                [styles.percentTitleNegative]:
                                  mainStats &&
                                  mainStats?.candidates?.percent < 0,
                              })}
                            >
                              {mainStats?.candidates?.percent}%
                            </p>
                          </>
                        )} */}
                    </div>
                  </div>
                </div>
                <div className={styles.infoBlock}>
                  <div className={styles.userBlock}>
                    <p className={styles.largeRegular}>Groups</p>
                    <p className={styles.h5}>0</p>
                  </div>
                  <div className={styles.todayBlock}>
                    <p className={styles.xSmallRegular}>Today</p>
                    <div className={styles.percentWrapper}>
                      <p className={styles.largeBold}>0</p>

                      {/* {mainStats?.employers?.percent !== null &&
                        mainStats?.employers?.percent !== 0 && (
                          <>
                            <div
                              className={clsx(styles.percentContainer, {
                                [styles.percentContainerNegative]:
                                  mainStats &&
                                  mainStats?.employers?.percent < 0,
                              })}
                            >
                              <ArrowSmallDownIcon />
                            </div>
                            <p
                              className={clsx(styles.percentTitle, {
                                [styles.percentTitleNegative]:
                                  mainStats &&
                                  mainStats?.employers?.percent < 0,
                              })}
                            >
                              {mainStats?.employers?.percent}%
                            </p>
                          </>
                        )} */}
                    </div>
                  </div>
                </div>
                <div className={styles.infoBlock}>
                  <div className={styles.userBlock}>
                    <p className={styles.largeRegular}>User Meals</p>
                    <p className={styles.h5}>{mainStats?.userRecipes?.all}</p>
                  </div>
                  <div className={styles.todayBlock}>
                    <p className={styles.xSmallRegular}>Today</p>
                    <div className={styles.percentWrapper}>
                      <p className={styles.largeBold}>
                        {mainStats?.userRecipes?.today}
                      </p>

                      {mainStats?.userRecipes?.percent !== null &&
                        mainStats?.userRecipes?.percent !== 0 && (
                          <>
                            <div
                              className={clsx(styles.percentContainer, {
                                [styles.percentContainerNegative]:
                                  mainStats &&
                                  mainStats?.userRecipes?.percent < 0,
                              })}
                            >
                              <ArrowSmallDownIcon />
                            </div>
                            <p
                              className={clsx(styles.percentTitle, {
                                [styles.percentTitleNegative]:
                                  mainStats &&
                                  mainStats?.userRecipes?.percent < 0,
                              })}
                            >
                              {mainStats?.userRecipes?.percent}%
                            </p>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div className={styles.infoBlock}>
                  <div className={styles.userBlock}>
                    <p className={styles.largeRegular}>Deleted Users</p>
                    <p className={styles.h5}>{mainStats?.deleted?.all}</p>
                  </div>
                  <div className={styles.todayBlock}>
                    <p className={styles.xSmallRegular}>Today</p>
                    <div className={styles.percentWrapper}>
                      <p className={styles.largeBold}>
                        {mainStats?.deleted?.today}
                      </p>

                      {mainStats?.deleted?.percent !== null &&
                        mainStats?.deleted?.percent !== 0 && (
                          <>
                            <div
                              className={clsx(styles.percentContainer, {
                                [styles.percentContainerNegative]:
                                  mainStats && mainStats?.deleted?.percent < 0,
                              })}
                            >
                              <ArrowSmallDownIcon />
                            </div>
                            <p
                              className={clsx(styles.percentTitle, {
                                [styles.percentTitleNegative]:
                                  mainStats && mainStats?.deleted?.percent < 0,
                              })}
                            >
                              {mainStats?.deleted?.percent}%
                            </p>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
      </div>
    </>
  );
};

export default Statistics;
