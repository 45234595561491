import React, { FC, useMemo } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import clsx from "clsx";
import {
  CheckBigIcon,
  CheckIcon,
  FastingIcon,
  HalfIcon20,
  HalftIcon,
} from "../../assets";
import MealPlanRecipeItem from "../MealPlanRecipeItem";

const MealPlanItem: FC<IProps> = ({
  isComplete,
  title,
  item,
  completeType,
}): JSX.Element => {
  const macro = useMemo(() => {
    const totalMacro = {
      cal: 0,
      p: 0,
      fat: 0,
      c: 0,
      fib: 0,
      pe: 0,
    };

    if (item?.foods?.length) {
      item?.foods?.forEach((item) => {
        totalMacro.cal += item.calories * item?.count;
        totalMacro.p += item.proteins * item?.count;
        totalMacro.fat += item.fats * item?.count;
        totalMacro.c += item.carbs * item?.count;
        totalMacro.fib += item.fiber * item?.count;
      });
    }

    return [
      {
        value: totalMacro.cal?.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        }),
        title: "cal",
      },
      {
        value: totalMacro?.p?.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        }),
        title: "p",
      },
      {
        value: totalMacro?.fat?.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        }),
        title: "fat",
      },
      {
        value: totalMacro?.c?.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        }),
        title: "c",
      },
      {
        value: totalMacro?.fib?.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        }),
        title: "fib",
      },
    ];
  }, [item]);
  return (
    <div className={clsx(styles.wrapper, {})}>
      <div className={styles.contentContainer}>
        <h4 className={styles.title}>{title}</h4>
        <div className={styles.recipesContainer}>
          {item?.foods?.map((item, index) => (
            <MealPlanRecipeItem item={item} key={item?.logRecipe_id} />
          ))}
          <div className={styles.skipContainer}>
            {completeType === "Fasting" && (
              <>
                <FastingIcon />
                <div className={styles.skipTitle}>Fasted</div>
              </>
            )}
            {completeType === "Average" && (
              <>
                <HalftIcon />
                <div className={styles.skipTitle}>
                  Average calories from previous days
                </div>
              </>
            )}

            {completeType === "Overeating" && (
              <>
                <HalfIcon20 />
                <div className={styles.skipTitle}>
                  Average calories from previous days + 20%
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {
        <div className={styles.macroContainer}>
          {macro.map((item, index) => (
            <React.Fragment key={index}>
              {index !== 0 && <div className={styles.macroDivider} />}
              <div className={styles.macroItem}>
                <div className={styles.macroItemValue}>
                  {item.value} {item.title !== "cal" && "g"}
                </div>
                <div className={styles.macroItemTitle}>{item.title}</div>
              </div>
            </React.Fragment>
          ))}
        </div>
      }
    </div>
  );
};

export default MealPlanItem;
