import * as yup from 'yup';

export const createStaffValidationScheme = yup.object().shape({
    fullName: yup.string()
        .required("Full Name is required")
        .min(2, ({min}) => `Full Name must be at least 2 characters long`,)
        .max(50, ({max}) => `Must be at most ${max} characters in length`),
    email: yup
        .string()
        .required('Email Address is required')
        .matches(/^\S*$/, "Mustn't contain spaces")
        .matches(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/,
            'Invalid email address',
        )
        .max(80, ({max}) => `Must be at most ${max} characters in length`),
    password: yup
        .string()
        .min(
            8,
            ({min}) =>
                `Password must be at least 8 characters long and include at least 1 uppercase letter, 1 lowercase letter, 1 symbol, and 1 number`,
        )
        .max(
            20,
            ({max}) =>
                `Password must be at least 8 characters long and include at least 1 uppercase letter, 1 lowercase letter, 1 symbol, and 1 number`,
        )
        .matches(
            /^(?=.*[a-z])/,
            'Password must be at least 8 characters long and include at least 1 uppercase letter, 1 lowercase letter, 1 symbol, and 1 number',
        )
        .matches(
            /^(?=.*[A-Z])/,
            'Password must be at least 8 characters long and include at least 1 uppercase letter, 1 lowercase letter, 1 symbol, and 1 number',
        )
        .matches(
            /^(?=.*[0-9])/,
            'Password must be at least 8 characters long and include at least 1 uppercase letter, 1 lowercase letter, 1 symbol, and 1 number',
        )
        .matches(
            /^(?=.*[?.,!_\-~$%+=@#^&])/,
            'Password must be at least 8 characters long and include at least 1 uppercase letter, 1 lowercase letter, 1 symbol, and 1 number',
        )
        .matches(/^\S*$/, "Mustn't contain spaces")
});
