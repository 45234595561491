import { FC, useEffect, useState } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { CalendarCheckIcon, ConsistencyIcon } from "../../assets";
import StreakCalendar from "../StreakCalendar";
import { getUserConsistenctStreak } from "../../services/users.service";
import { format, getDaysInMonth } from "date-fns";

const ConsistencyStreakCalendar: FC<IProps> = ({ userInfo }): JSX.Element => {
  const [activeDate, setActiveDate] = useState(new Date());
  const [data, setData] = useState<any>();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [monthLength, setMonthLength] = useState(getDaysInMonth(new Date()));

  useEffect(() => {
    setMonthLength(getDaysInMonth(new Date(activeDate)));
  }, [activeDate]);

  const onGetData = async () => {
    try {
      setIsLoadingData(true);
      const { data } = await getUserConsistenctStreak(
        userInfo.id,
        format(new Date(activeDate), "yyyy-MM-dd")
      );
      if (data?.success) {
        setData(data?.data);
      }
    } catch (error) {
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    onGetData();
  }, [activeDate]);

  return (
    <div className={styles.wrapper}>
      <h5 className={styles.title}>Consistency Streak </h5>

      <div className={styles.circularProgressContainer}>
        <div className={styles.circularWrapper}>
          <CircularProgressbarWithChildren
            value={data?.consistency || 0}
            circleRatio={0.7}
            className={styles.circularProgress}
            strokeWidth={7}
            styles={buildStyles({
              rotation: 0.65,
              pathColor: `#FFA800`,
              trailColor: "rgba(255, 168, 0, 0.2)",
            })}
          >
            <div className={styles.circularInner}>
              <ConsistencyIcon className={styles.consistencyIcon} />
              <h2 className={styles.value}>
                {data?.consistency || 0}
                <span className={styles.unit}>%</span>
              </h2>
              <span className={styles.subtitle}>CONSISTENCY</span>
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <div className={styles.circularWrapper}>
          <CircularProgressbarWithChildren
            value={((data?.streak || 0) / monthLength) * 100}
            circleRatio={0.7}
            className={styles.circularProgress}
            strokeWidth={7}
            styles={buildStyles({
              rotation: 0.65,
              pathColor: `#612AFF`,
              trailColor: "rgba(97, 42, 255, 0.2)",
            })}
          >
            <div className={styles.circularInner}>
              <CalendarCheckIcon className={styles.calendarIcon} />
              <h2 className={styles.value}>
                {data?.streak}
                <span className={styles.unit}> days</span>
              </h2>
              <span className={styles.subtitle}>STREAK</span>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div>
      <div className={styles.calendarWrapper}>
        <StreakCalendar
          data={!isLoadingData && data?.statistic}
          activeDate={activeDate}
          setActiveDate={setActiveDate}
        />
      </div>
    </div>
  );
};

export default ConsistencyStreakCalendar;
