import { FC } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { LikeLinearIcon, UserIcon } from "../../assets";

const CommentItem: FC = (): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.commentHeader}>
        <div className={styles.imageWrapper}>
          <UserIcon />
        </div>
        <span className={styles.title}>Angel Septimus</span>
        <span className={styles.online}>2 min ago</span>
      </div>
      <div className={styles.commentContainer}>
        <p>Looks like an intense workout, keep pushing yourself!</p>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerItem}>
          <LikeLinearIcon />
          <span>Like</span>
        </div>

        <span>Reply</span>
      </div>
    </div>
  );
};

export default CommentItem;
