import React, { ChangeEvent, FC, useCallback, useRef, useState } from "react";
import styles from "./styles.module.scss";
import {
  CheckBigIcon,
  CrossIcon,
  DotsVertical,
  PenciSmallIcon,
  PencilIcon,
  TrashIcon,
} from "../../assets";
import { useOutsideClick } from "../../hooks";
import { motion } from "framer-motion";
import { IProps } from "./types";
import { deleteTags, updateTags } from "../../services/recipe.service";
import toast from "react-hot-toast";
import { Spinner } from "react-activity";
import { Delete } from "../../modals";

const EditableTag: FC<IProps> = ({
  title = "",
  withCounter = false,
  counter = 0,
  onGetTags,
  maxLength = 200,
}): JSX.Element => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editTitle, setEditTitle] = useState(title);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const ref = useRef(null);

  useOutsideClick(ref, () => setIsOpenMenu(false));

  const editTitleEditHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEditTitle(e.target.value);
    },
    []
  );

  const onDeleteTag = async () => {
    try {
      setIsLoadingDelete(true);
      const { data } = await deleteTags(title);
      if (data?.success) {
        toast.success("Tag was successfully deleted");
        setIsOpenMenu(false);
        onGetTags();
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong"
      );
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const onUpdateTag = async (oldValue: string, newValue: string) => {
    try {
      setIsLoadingUpdate(true);

      const { data } = await updateTags(oldValue, newValue);
      if (data?.success) {
        toast.success("Tag was successfully edited");
        setIsEditOpen(false);
        onGetTags();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong"
      );
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  if (isEditOpen) {
    return (
      <div className={styles.categoryEditItem}>
        <div
          onClick={() => {
            setIsEditOpen(false);
            setEditTitle(title);
          }}
          className={styles.categoryEditItemCancel}
        >
          <CrossIcon />
        </div>

        <input
          value={editTitle}
          onChange={editTitleEditHandler}
          className={styles.editInput}
          maxLength={maxLength}
        />
        <div
          onClick={() => {
            if (editTitle.length > 1) {
              onUpdateTag(title, editTitle);
            }
          }}
          className={styles.categoryEditItemSave}
        >
          <CheckBigIcon
            style={{ fill: editTitle?.length < 2 ? "#b2b3bd" : "#612aff" }}
            width={20}
            height={20}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <Delete
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        isLoading={isLoadingDelete}
        onDelete={onDeleteTag}
        title="Are you sure you want to delete this tag?"
      />
      <div
        ref={ref}
        onClick={() => setIsOpenMenu((prev) => !prev)}
        style={{ background: isOpenMenu ? "#612AFF" : "#DFD4FF" }}
        className={styles.categoryItem}
      >
        <span
          style={{ color: isOpenMenu ? "#fff" : "#612AFF" }}
          className={styles.categoryTitle}
        >
          {title}
        </span>
        {withCounter && (
          <div className={styles.counterWrapper}>
            <span>{counter}</span>
          </div>
        )}
        <DotsVertical
          width={24}
          height={24}
          style={{ fill: isOpenMenu ? "#FFF" : "#612AFF" }}
          className={styles.dotMenu}
        />
        {isOpenMenu && (
          <motion.div
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
            className={styles.menuWrapper}
          >
            <div
              onClick={() => setIsEditOpen(true)}
              className={styles.menuButton}
            >
              <PenciSmallIcon />
              <span>Edit</span>
            </div>
            <div
              onClick={() => {
                setIsOpenDeleteModal(true);
              }}
              className={styles.menuButton}
            >
              <TrashIcon />
              <span style={{ color: "#EE3758" }}>Delete</span>
            </div>
          </motion.div>
        )}
      </div>
    </>
  );
};

export default EditableTag;
