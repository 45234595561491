import React, { FC, useEffect, useState } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowDownIcon, ArrowSmallDownIcon } from "../../assets";
import { Button, CheckBox, Selector } from "..";
import clsx from "clsx";

const LeaderboardFilter: FC<IProps> = ({ setIsOpen }): JSX.Element => {
  const [lifeStyle, setLifeStyle] = useState("All");
  const [ageFrom, setAgeFrom] = useState("18");
  const [ageTo, setAgeTo] = useState("99");
  const [activity, setActivity] = useState("All");
  const [diet, setDiet] = useState("All");

  return (
    <motion.div
      initial={{ scale: 0.8, opacity: 0.5 }}
      animate={{ scale: 1, opacity: 1 }}
      className={styles.wrapper}
    >
      <h5 className={styles.title}>Filter</h5>
      <div className={styles.sectionDevider} />
      <div className={styles.sectionHeader}>
        <span className={styles.sectionTitle}>Lifestyle</span>
      </div>
      <Selector value={lifeStyle} setValue={setLifeStyle} data={["All"]} />
      <div className={styles.sectionDevider} />

      <div className={styles.sectionHeader}>
        <span className={styles.sectionTitle}>Age</span>
      </div>
      <div className={styles.selectorRowContainer}>
        <Selector
          value={ageFrom}
          setValue={setAgeFrom}
          data={["18"]}
          wrapperStyles={styles.selector}
        />
        <Selector
          value={ageTo}
          setValue={setAgeTo}
          data={["99"]}
          wrapperStyles={styles.selector}
        />
      </div>
      <div className={styles.sectionDevider} />

      <div className={styles.sectionHeader}>
        <span className={styles.sectionTitle}>Activity</span>
      </div>
      <Selector value={activity} setValue={setActivity} data={["All"]} />
      <div className={styles.sectionDevider} />

      <div className={styles.sectionHeader}>
        <span className={styles.sectionTitle}>Diet</span>
      </div>
      <Selector value={diet} setValue={setDiet} data={["All"]} />

      <div className={styles.sectionDevider} />
      <div onClick={() => {}} className={styles.resetButton}>
        <div>Reset all filters</div>
      </div>
    </motion.div>
  );
};

export default LeaderboardFilter;
