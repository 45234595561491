import React, { FC } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import clsx from "clsx";

const MacroItem: FC<IProps> = ({ value, title }): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <p className={clsx(styles.value, { [styles.opacity]: value === 0 })}>
        {value?.toLocaleString(undefined, { maximumFractionDigits: 1 })}
        {title !== "cal" ? (title === "P/E" ? "%" : " g") : ""}
      </p>
      <p className={clsx(styles.title, { [styles.opacity]: value === 0 })}>
        {title}
      </p>
    </div>
  );
};

export default MacroItem;
