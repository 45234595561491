//@ts-nocheck
import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import {
  CrossIcon,
  DublicateIcon,
  PencilIcon,
  RecipeIcon,
  ServingIcon,
  TrashIcon,
  UserIcon,
} from "../../assets";
import { Tag, RecipeIngredient } from "../../components";
import { IConfigurableIngredient } from "../../pages/Dashboard/CreateRecipe/types";
import { IRecipe } from "../../models";
import {
  createRecipe,
  deleteRecipe,
  getRecipeById,
} from "../../services/recipe.service";
import { PulseLoader } from "react-spinners";
import clsx from "clsx";
import { Delete } from "../index";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Recipe: FC<IProps> = ({
  isOpen,
  setIsOpen,
  recipeId,
  onGetRecipes,
}): JSX.Element => {
  const [isLoadingRecipe, setIsLoadingRecipe] = useState(false);
  const [recipe, setRecipe] = useState<IRecipe>();
  const [ingredients, setIngredients] = useState<IConfigurableIngredient[]>([]);
  const [isShowDelete, setIsShowDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingDublicate, setIsLoadingDublicate] = useState(false);
  const navigation = useNavigate();

  const onDelete = async () => {
    try {
      setIsLoadingDelete(true);

      const { data } = await deleteRecipe(recipeId);

      if (data?.success) {
        onGetRecipes(1, "");
      }
    } catch (error) {
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const onDublicate = async () => {
    try {
      setIsLoadingDublicate(true);
      setIsOpen(false);
      const { data } = await getRecipeById(recipeId);
      let recipe = data?.data;
      if (data?.success) {
      }
      const res = await createRecipe(
        `${recipe?.name} (duplicated)`,
        recipe?.tags,
        recipe?.category,
        recipe?.method || [],
        recipe?.ingredients,
        recipe?.description || "",
        recipe?.image || "",
        recipe?.numberOfServes || 0,
        recipe?.gramsPerServe || "",
        recipe?.gramsPerServeUnit || ""
      );
      if (res?.data?.success) {
        toast.success("Meal has been successfully duplicated");
        onGetRecipes(1, "");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong"
      );
    } finally {
      setIsLoadingDublicate(false);
    }
  };

  const onGetRecipeInfo = async () => {
    try {
      setIsLoadingRecipe(true);
      const { data } = await getRecipeById(recipeId);
      if (data?.success) {
        setRecipe(data?.data);
        setIngredients(
          data?.data?.ingredients?.map((item: any) => ({
            ...item,
            id: item?.ingredient_id,
            calculated: true,
          }))
        );
      }
    } catch (e) {
    } finally {
      setIsLoadingRecipe(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      onGetRecipeInfo();
    }
  }, [isOpen]);

  const macro = {
    cal: recipe?.calories,
    Protein: recipe?.proteins,
    Fat: recipe?.fats,
    Carbs: recipe?.carbs,
    Fiber: recipe?.fiber,
    "P/E": recipe?.pe,
  };

  const convertIngredients = (
    ingredient: IConfigurableIngredient
  ): IConfigurableIngredient => {
    const ingredeintMacro = ingredient.units.find(
      (item) => item.name === ingredient.unit
    );
    if (ingredeintMacro) {
      ingredient = {
        ...ingredient,
        id: ingredient.id.toString(),
        name: ingredient.name,
        category: ingredient.category,
        //@ts-ignore
        proteins: +(ingredeintMacro?.proteins * ingredient.count).toFixed(2),
        //@ts-ignore

        fats: +(ingredeintMacro?.fats * ingredient.count).toFixed(2),
        //@ts-ignore

        carbs: +(ingredeintMacro?.carbs * ingredient.count).toFixed(2),
        //@ts-ignore

        fiber: +(ingredeintMacro?.fiber * ingredient.count).toFixed(2),

        //@ts-ignore
        calories: +(ingredeintMacro?.calories * ingredient.count).toFixed(2),
      };
    }

    return { ...ingredient };
  };

  return (
    <>
      <Delete
        isOpen={isShowDelete}
        setIsOpen={setIsShowDelete}
        onDelete={onDelete}
        isLoading={isLoadingDelete}
        title={"Are you sure you want to delete this recipe?"}
      />
      <Modal
        isOpen={isOpen}
        shouldFocusAfterRender={false}
        onRequestClose={() => setIsOpen(false)}
        overlayClassName={styles.overlay}
        className={styles.modal}
        ariaHideApp={false}
      >
        <div className={styles.innerContainer}>
          {isLoadingRecipe ? (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#7D4AFB"} />
            </div>
          ) : (
            <>
              <div className={styles.imageWrapper}>
                <div className={styles.headerButtonsContainer}>
                  <div
                    onClick={() => {
                      setIsOpen(false);
                      setIsShowDelete(true);
                    }}
                    className={clsx(styles.editButton, styles.deleteButton)}
                  >
                    <TrashIcon style={{ width: "22px", height: "22px" }} />
                  </div>
                  <button
                    disabled={isLoadingDublicate}
                    onClick={onDublicate}
                    className={styles.editButton}
                  >
                    <DublicateIcon />
                  </button>
                  <div
                    onClick={() => {
                      setIsOpen(false);
                      navigation(`/edit-recipe/${recipeId}`);
                    }}
                    className={styles.editButton}
                  >
                    <PencilIcon style={{ width: "20px", height: "20px" }} />
                  </div>
                  <div
                    onClick={() => setIsOpen(false)}
                    className={styles.editButton}
                  >
                    <CrossIcon />
                  </div>
                </div>
                {recipe?.image ? (
                  <img src={recipe?.image} className={styles.image} />
                ) : (
                  <RecipeIcon className={styles.recipeIcon} />
                )}
                <div className={styles.servingContainer}>
                  {recipe?.numberOfServes && (
                    <div className={styles.servingButton}>
                      <UserIcon />
                      <span className={styles.servingTitle}>
                        {recipe?.numberOfServes}
                      </span>
                    </div>
                  )}
                  {recipe?.gramsPerServe && (
                    <div className={styles.servingButton}>
                      <ServingIcon />
                      <span className={styles.servingTitle}>
                        {recipe?.gramsPerServe}{" "}
                        {recipe?.gramsPerServeUnit || "g"}/serve
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.contentContainer}>
                <h3 className={styles.title}>{recipe?.name}</h3>
                <p className={styles.category}>{recipe?.category}</p>
                <div className={styles.macroSectionTitle}>
                  Energy per portion:
                </div>
                {
                  <div className={styles.macroContainer}>
                    {Object.entries(macro).map((item, index) => (
                      <div key={index} className={styles.macroItem}>
                        <span className={styles.macroValue}>
                          {item[1]?.toLocaleString(undefined, {
                            maximumFractionDigits: item[0] === "cal" ? 0 : 1,
                          })}
                          {item[0] !== "cal"
                            ? item[0] === "P/E"
                              ? "%"
                              : " g"
                            : ""}
                        </span>
                        <span className={styles.macroTitle}>{item[0]}</span>
                      </div>
                    ))}
                  </div>
                }
                <p className={styles.description}>{recipe?.description}</p>
                <div className={styles.tagsContainer}>
                  {recipe?.tags.map((item, index) => (
                    <Tag key={index} title={item} />
                  ))}
                </div>
                {ingredients?.length > 0 && (
                  <>
                    <p className={styles.sectionTitle}>Ingredients</p>
                    <div className={styles.ingredientsList}>
                      {ingredients.map((item, index) => {
                        return (
                          <div key={index}>
                            <RecipeIngredient item={convertIngredients(item)} />
                            {index + 1 !== ingredients?.length && (
                              <div className={styles.ingredientsDevider} />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
                <div className={styles.macroSectionTitle}>Total energy:</div>
                {
                  <div className={styles.macroContainer}>
                    {Object.entries(macro).map((item, index) => (
                      <div key={index} className={styles.macroItem}>
                        <span className={styles.macroValue}>
                          {(
                            item[1] *
                            (item[0] === "P/E"
                              ? 1
                              : recipe?.numberOfServes || 1)
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: item[0] === "cal" ? 0 : 1,
                          })}
                          {item[0] !== "cal"
                            ? item[0] === "P/E"
                              ? "%"
                              : "g"
                            : ""}
                        </span>
                        <span className={styles.macroTitle}>{item[0]}</span>
                      </div>
                    ))}
                  </div>
                }
                {recipe?.preparations && recipe?.preparations?.length && (
                  <>
                    <p className={styles.sectionTitle}>Method</p>
                    <div className={styles.methodList}>
                      {recipe?.preparations.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className={styles.methodItem}>
                              <div className={styles.iconContainer}>
                                <span>{index + 1}</span>
                              </div>
                              <p className={styles.description}>{item}</p>
                            </div>
                            {index + 1 !== recipe?.preparations?.length && (
                              <div className={styles.methodDevider} />
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Recipe;
