import { FC, useEffect, useRef, useState } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import {
  add,
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isLastDayOfMonth,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { ArrowSmallDownIcon } from "../../assets";
import clsx from "clsx";

const StreakCalendar: FC<IProps> = ({
  activeDate,
  setActiveDate,
  data,
}): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [activeDate, setActiveDate] = useState(new Date());
  const [dayItemWidth, setDayItemWidth] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDayItemWidth((ref.current?.offsetWidth || 0) / 7);
  }, [ref.current]);

  const onPrevMonth = () => {
    setActiveDate(add(activeDate, { months: -1 }));
  };

  const onNextMonth = () => {
    setActiveDate(add(activeDate, { months: 1 }));
  };

  const generateDatesForCurrentWeek = (
    date: Date,
    selectedDate: Date,
    activeDate: Date
  ) => {
    let currentDate = date;
    const week = [];
    for (let day = 0; day < 7; day++) {
      const cloneDate = currentDate;

      let markedOptions = {
        isFirst: false,
        isLast: false,
        isOne: false,
        isMarked: false,
        isOrange: false,
      };
      if (data) {
        const indexInData = data?.findIndex(
          (item) =>
            format(new Date(item.date), "yyyy-MM-dd") ===
            format(new Date(cloneDate), "yyyy-MM-dd")
        );

        const isDayMarked = (prevDate: Date) => {
          const index = data?.findIndex(
            (item) =>
              format(new Date(item.date), "yyyy-MM-dd") ===
              format(new Date(prevDate), "yyyy-MM-dd")
          );

          if (index !== -1 && data[index].goal) {
            return true;
          }
          return false;
        };

        if (indexInData !== -1 && !data[indexInData].goal) {
          markedOptions.isOrange = true;
        }
        if (indexInData !== -1 && data[indexInData].goal) {
          markedOptions.isMarked = true;
          const indexDayInWeek = getDay(new Date(cloneDate));

          if (
            indexInData === 0 ||
            indexDayInWeek === 0 ||
            !isDayMarked(add(new Date(cloneDate), { days: -1 }))
          ) {
            markedOptions.isFirst = true;
          }
          if (
            (data && indexInData === data.length - 1) ||
            indexDayInWeek === 6 ||
            isLastDayOfMonth(cloneDate) ||
            !isDayMarked(add(new Date(cloneDate), { days: 1 }))
          ) {
            markedOptions.isLast = true;
          }
          if (
            indexInData !== -1 &&
            (!isDayMarked(add(new Date(cloneDate), { days: -1 })) ||
              indexDayInWeek === 0) &&
            !isDayMarked(add(new Date(cloneDate), { days: 1 }))
          ) {
            markedOptions.isOne = true;
          }
        }
      }

      week.push(
        <div
          key={cloneDate.toISOString()}
          style={{ width: dayItemWidth, height: dayItemWidth }}
          className={styles.dayWrapper}
        >
          <div
            className={clsx(styles.dayStreakContainer, {
              [styles.daySteakMarked]: markedOptions.isMarked,
              [styles.daySteakFirstMarked]: markedOptions.isFirst,
              [styles.dayStreakLastMarked]: markedOptions.isLast,
              [styles.daySteakOneMarked]: markedOptions.isOne,
              [styles.daySteakOrange]: markedOptions.isOrange
            })}
          >
            <div
              className={clsx(styles.day, {
                [styles.inactiveDay]: !isSameMonth(currentDate, activeDate),
                [styles.selectedDay]: isSameDay(currentDate, selectedDate),
                [styles.today]: isSameDay(currentDate, new Date()),
                [styles.streakDay]: true,
              })}
              onClick={() => {
                setSelectedDate(cloneDate);
              }}
            >
              {format(currentDate, "d")}
            </div>
          </div>
        </div>
      );
      currentDate = addDays(currentDate, 1);
    }
    return <>{week}</>;
  };

  const getDates = () => {
    const startOfTheSelectedMonth = startOfMonth(activeDate);
    const endOfTheSelectedMonth = endOfMonth(activeDate);
    const startDate = startOfWeek(startOfTheSelectedMonth);
    const endDate = endOfWeek(endOfTheSelectedMonth);

    let currentDate = startDate;

    const allWeeks = [];

    while (currentDate <= endDate) {
      allWeeks.push(
        generateDatesForCurrentWeek(currentDate, selectedDate, activeDate)
      );
      currentDate = addDays(currentDate, 7);
    }

    return <div className={styles.weekContainer}>{allWeeks}</div>;
  };

  const getWeekDaysNames = () => {
    const weekStartDate = startOfWeek(activeDate);
    const weekDays = [];
    for (let day = 0; day < 7; day++) {
      weekDays.push(
        <div
          key={day}
          style={{ width: dayItemWidth }}
          className={styles.weekName}
        >
          {format(addDays(weekStartDate, day), "E")}
        </div>
      );
    }
    return weekDays;
  };

  return (
    <div ref={ref} className={styles.wrapper}>
      <div className={styles.dateHeader}>
        <div onClick={onPrevMonth} className={styles.dateHeaderButton}>
          <ArrowSmallDownIcon className={styles.leftArrow} />
        </div>
        <p className={styles.dateTitle}>{format(activeDate, "MMM, yyyy")}</p>
        <div onClick={onNextMonth} className={styles.dateHeaderButton}>
          <ArrowSmallDownIcon className={styles.rightArrow} />
        </div>
      </div>
      <div className={styles.weekDaysNamesContainer}>{getWeekDaysNames()}</div>

      {getDates()}
    </div>
  );
};

export default StreakCalendar;
