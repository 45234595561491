import { FC, useLayoutEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { IProps } from "./types";
import { add, format } from "date-fns";
import { TriangleIcon } from "../../assets";

const WeightLossGoal: FC<IProps> = ({ userInfo }): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const [tooltipMargin, setTooltipMargin] = useState(0);
  const progress = useMemo(() => {
    if (!userInfo) {
      return 0;
    }

    const diferenceInWeight = userInfo?.startWeight - userInfo?.finishWeight;
    const currentDifferenceInWeight =
      userInfo?.currentWeight - userInfo?.finishWeight;
    if (currentDifferenceInWeight > diferenceInWeight) {
      return 0;
    }

    let result =
      ((diferenceInWeight - currentDifferenceInWeight) / diferenceInWeight) *
      100;
    if (result > 100) {
      return 100;
    } else {
      return result;
    }
  }, [userInfo]);

  useLayoutEffect(() => {
    if (ref.current?.offsetWidth) {
      const margin = ref.current?.offsetWidth * (progress / 100) - 58.5;

      if (margin + 58.5 > ref.current?.offsetWidth - 58.5) {
        setTooltipMargin(ref.current?.offsetWidth - 117);
      }

      if (margin + 58.5 > 117 / 2) {
        setTooltipMargin(margin);
      }
    }
  }, [progress, ref.current]);

  return (
    <div ref={ref} className={styles.wrapper}>
      <h5 className={styles.title}>Weight Loss Goal</h5>
      <div
        style={{ marginLeft: tooltipMargin }}
        className={styles.progressTooltip}
      >
        <div className={styles.progressTooltipInner}>
          <div className={styles.progressTooltipTitle}>CURRENT WEIGHT</div>
          <div className={styles.progressTooltipSubtitle}>
            {userInfo?.currentWeight?.toFixed(1)} {userInfo?.weightUnit}
          </div>
        </div>
      </div>
      <div className={styles.progressContainer}>
        <div style={{ width: `${progress}%` }} className={styles.progress}>
          {progress > 5 && progress < 95 && (
            <TriangleIcon
              style={{
                position: "absolute",
                right: "-7.5px",
                top: "-15px",
                fill: "#000",
              }}
            />
          )}
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.rowContainer}>
          <div className={styles.weightItem}>
            <span className={styles.weightTitle}>
              {userInfo?.startWeight?.toFixed(1)}{" "}
              <span className={styles.weightUnit}>{userInfo?.weightUnit}</span>
            </span>
            <span className={styles.date}>
              {userInfo?.onboardingAt &&
                format(new Date(userInfo?.onboardingAt || ""), "PP")}
            </span>
          </div>
          <div className={styles.weightItem}>
            <span className={styles.weightTitle}>
              {userInfo?.finishWeight?.toFixed(1)}{" "}
              <span className={styles.weightUnit}>{userInfo?.weightUnit}</span>
            </span>
            <span className={styles.date}>
              {userInfo?.onboardingAt &&
                format(
                  add(new Date(userInfo?.onboardingAt || ""), {
                    weeks: userInfo?.weeks || 0,
                  }),
                  "PP"
                )}
            </span>
          </div>
        </div>
      </div>
      <span className={styles.deficit}>
        {userInfo?.deficitInDay?.toFixed(0)}{" "}
        <span className={styles.deficitUnit}>cal</span>
      </span>

      <div className={styles.warningContainer}>
        <span className={styles.warningTitle}>
          Required daily calorie deficit to reach your current goal
        </span>
      </div>
    </div>
  );
};

export default WeightLossGoal;
