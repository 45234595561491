import { FC, InputHTMLAttributes } from "react";
import { SearchIcon } from "../../assets";
import styles from "./styles.module.scss";

interface Props extends InputHTMLAttributes<HTMLInputElement> {}

const SearchInput: FC<Props> = (props): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <SearchIcon />
      <input {...props} className={styles.input} />
    </div>
  );
};

export default SearchInput;
