import React, { ChangeEvent, FC } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import Input from "../Input";
import Selector from "../Selector";
import { TrashIcon } from "../../assets";
import { IConfigurableIngredient } from "../../pages/Dashboard/CreateRecipe/types";

const ConfigurableIngredient: FC<IProps> = ({
  item,
  setIngredients,
  index,
}): JSX.Element => {
  const onChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    setIngredients((prev) => {
      const copy = [...prev];
      copy[index].count = e.target.value;
      return copy;
    });
  };

  const onSelectUnit = (unit: string) => {
    //@ts-ignore
    setIngredients((prev) => {
      const copy = [...prev];
      copy[index].unit = unit;
      return copy;
    });
  };

  const onDelete = () => {
    //@ts-ignore
    setIngredients((prev) => {
      const copy = [...prev];
      copy.splice(index, 1);
      return copy;
    });
  };

  const macro = {
    cal: item?.calories?.toLocaleString(undefined, {
      maximumFractionDigits: 1,
    }),
    Protein: item?.proteins?.toLocaleString(undefined, {
      maximumFractionDigits: 1,
    }),
    Fat: item?.fats?.toLocaleString(undefined, {
      maximumFractionDigits: 1,
    }),
    Carbs: item?.carbs?.toLocaleString(undefined, {
      maximumFractionDigits: 1,
    }),
    Fiber: item?.fiber?.toLocaleString(undefined, {
      maximumFractionDigits: 1,
    }),
    Alco: item?.alco?.toLocaleString(undefined, {
      maximumFractionDigits: 1,
    }),
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.infoContainer}>
        <div style={{ flex: 1 }}>
          <div className={styles.nameContainer}>
            <span className={styles.name}>{item.name}</span>
            {item?.brand && <span className={styles.brand}>{item?.brand}</span>}
          </div>
        </div>
        {/* <Input value={item.name} disabled wrapperStyles={styles.nameInput} /> */}
        <Input
          value={item.count}
          onChange={onChangeAmount}
          wrapperStyles={styles.amountInput}
          type="number"
        />
        <Selector
          value={item.unit === "serving" ? "1 serving size" : item.unit}
          description={
            item.unit === "serving"
              ? item?.units?.find((el) => el.name === item.unit)?.description
              : ""
          }
          setValue={(value) => {
            const existUnit = item?.units?.find((el) => el.name === value);
            if (existUnit) {
              onSelectUnit(value);
            } else {
              const findUnit = item?.units?.find(
                (el) => el.description === value
              );
              onSelectUnit(findUnit?.name!);
            }
          }}
          data={item.units
            ?.filter((item) => item.status)
            .map((item) =>
              item.name === "serving" ? item?.description : item?.name
            )}
          wrapperStyles={styles.unitInput}
        />
      </div>
      <div className={styles.sectionDivider} />
      <div className={styles.macroContainer}>
        <div className={styles.calories}>{macro?.cal} cal</div>
        <div className={styles.macroItem}>{macro?.Protein} g</div>
        <div className={styles.macroItem}>{macro?.Fat} g</div>
        <div className={styles.macroItem}>{macro?.Carbs} g</div>
        <div className={styles.macroItem}>{macro?.Fiber} g</div>
        <div className={styles.macroItem}>{macro?.Alco} g</div>
      </div>

      <TrashIcon onClick={onDelete} className={styles.deleteButton} />
    </div>
  );
};

export default ConfigurableIngredient;
