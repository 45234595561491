import axiosInstance from "../apis";
import { AxiosResponse } from "axios";

export const getAllStaff = async (
  sortValue?: "fullName" | "email" | "type" | null,
  order?: -1 | 1 | null
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params: any = {};

    if (sortValue) {
      params.sortValue = sortValue;
    }

    if (order) {
      params.order = order;
    }

    axiosInstance
      .get("admins/search", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const createStaff = (
  email: string,
  password: string,
  fullName: string,
  type: string,
  image: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body: any = {
      email,
      password,
      fullName,
      type,
    };
    if (image) {
      body.image = image;
    }
    axiosInstance
      .post("auth/create", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const updateStaff = (
  email: string,
  password: string,
  fullName: string,
  type: string,
  image: string,
  id: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let body: any = {
      email,
      fullName,
      type,
      image,
      admin_id: id,
    };
    if (password) {
      body.password = password;
    }

    axiosInstance
      .put("admins/update", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const deleteStaff = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admins/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
