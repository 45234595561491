import React, { ChangeEvent, FC, useState } from "react";
import styles from "./styles.module.scss";
import { PulseLoader } from "react-spinners";
import {
  Button,
  CheckBox,
  IngredientItem,
  PaginationButtons,
  SearchInput,
  EditableTag,
} from "../../../components";
import {
  AddSquareIcon,
  ArrowSmallDownIcon,
  PlaySquareIcon,
} from "../../../assets";
import { getIngredients } from "../../../services/ingredients.service";
import { CreateUnit } from "../../../modals";

const pageLimit = 20;

const Units: FC = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState(1);
  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <CreateUnit isOpen={isOpenCreateModal} setIsOpen={setIsOpenCreateModal} />
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <h4 className={styles.title}>Units</h4>
          <div className={styles.headerAddButton}>
            <Button
              title={"New Unit"}
              leftIcon={<AddSquareIcon />}
              onClick={() => setIsOpenCreateModal(true)}
              styleType="filled"
              size="small"
            />
          </div>
        </header>
        <section className={styles.contentContainer}>
          <div className={styles.searchContainer}>
            <SearchInput
              placeholder="Find units..."
              value={search}
              onChange={onChangeSearch}
            />
          </div>
          <div className={styles.listContainer}>
            {/* {[1, 2, 3, 4, 4].map((item, index) => (
              <EditableTag key={index} title={"sss"} />
            ))} */}
          </div>
        </section>
      </div>
    </>
  );
};

export default Units;
