import React, { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { SearchIcon } from "../../assets";
import { IProps } from "./types";
import { useDebounce, useOutsideClick } from "../../hooks";
import { motion } from "framer-motion";
import { getIngredients } from "../../services/ingredients.service";
import { IIngredient } from "../../models";
import { PulseLoader } from "react-spinners";
import Highlighter from "react-highlight-words";
const pageLimit = 20;

const SearchIngredients: FC<IProps> = ({
  blockRef,
  setIsShow,
  onSelectIngredient,
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 300);
  const [ingredients, setIngredients] = useState<IIngredient[]>([]);
  const [pagesCount, setPageCount] = useState(0);

  useOutsideClick(blockRef, () => setIsShow(false));

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetIngredients = async (page: number, search: string) => {
    try {
      page === 1 && setIsLoading(true);
      page > 1 && setIsLoadingPagination(true);
      const { data } = await getIngredients(page, pageLimit, search);
      if (data.success) {
        if (page === 1) {
          setIngredients(data?.data?.ingredients);
        } else {
          setIngredients((prev) => [...prev, ...data?.data?.ingredients]);
        }

        setPageCount(Math.ceil(data?.data?.count / pageLimit));
        setPage(+data?.data?.page);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      setIsLoadingPagination(false);
    }
  };

  const handleScroll = (e: any) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)
      ) <= 1;
    if (bottom && !isLoading && !isLoadingPagination && pagesCount !== page) {
      onGetIngredients(+page + 1, debounceSearch);
      // setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (debounceSearch) {
      onGetIngredients(1, debounceSearch);
    }
  }, [debounceSearch]);

  useEffect(() => {
    if (!debounceSearch) {
      onGetIngredients(1, debounceSearch);
    }
  }, [debounceSearch]);

  return (
    <motion.div
      initial={{ scale: 0.8 }}
      animate={{ scale: 1, opacity: 1 }}
      className={styles.wrapper}
    >
      <div className={styles.searchWrapper}>
        <SearchIcon className={styles.searchIcon} />
        <input
          value={search}
          onChange={onChangeSearch}
          placeholder="Find ingredient"
          className={styles.input}
        />
      </div>
      <div onScroll={handleScroll} className={styles.listContainer}>
        {isLoading ? (
          <div className={styles.loaderWrapper}>
            <PulseLoader size={10} color={"#7D4AFB"} />
          </div>
        ) : (
          ingredients.map((item, index) => (
            <div
              onClick={() => {
                setIsShow(false);

                //@ts-ignore
                onSelectIngredient((prev) => [
                  ...prev,
                  {
                    ...item,
                    count: 1,
                    unit: item.units?.find((el) => el.isDefault)?.name,
                  },
                ]);
              }}
              key={item.id}
              className={styles.listItem}
            >
              <span className={styles.listItemTitle}>
                <Highlighter
                  highlightClassName={styles.name}
                  searchWords={debounceSearch?.split(" ")}
                  autoEscape={true}
                  textToHighlight={item.name}
                />{" "}
                <span className={styles.brand}>
                  ·{" "}
                  <Highlighter
                    highlightClassName={styles.brandHighlighter}
                    searchWords={debounceSearch?.split(" ")}
                    autoEscape={true}
                    textToHighlight={item?.brand || ""}
                  />
                </span>
              </span>
            </div>
          ))
        )}
        {isLoadingPagination && (
          <div className={styles.paginationLoaderWrapper}>
            <PulseLoader size={10} color={"#7D4AFB"} />
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default SearchIngredients;
