import React, { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Button, SearchInput, EditableTag } from "../../../components";
import { AddSquareIcon } from "../../../assets";
import { CreateTag } from "../../../modals";
import { getTags } from "../../../services/recipe.service";
import { PulseLoader } from "react-spinners";
import { useDebounce } from "../../../hooks";

interface ITags {
  value: string;
  usage: number;
}

const Tags: FC = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const debounceSearch = useDebounce(search, 300);

  const [tags, setTags] = useState<ITags[]>([]);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetTags = async () => {
    try {
      setIsLoading(true);
      const { data } = await getTags(debounceSearch);
      if (data?.success) {
        setTags(data?.data?.tags);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetTags();
  }, [debounceSearch]);

  return (
    <>
      <CreateTag
        onGetTags={onGetTags}
        isOpen={isOpenCreateModal}
        setIsOpen={setIsOpenCreateModal}
      />
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <h4 className={styles.title}>Tags</h4>
          <div className={styles.headerAddButton}>
            <Button
              title={"Create New Tag"}
              leftIcon={<AddSquareIcon />}
              onClick={() => setIsOpenCreateModal(true)}
              styleType="filled"
              size="small"
            />
          </div>
        </header>
        <section className={styles.contentContainer}>
          <div className={styles.searchContainer}>
            <SearchInput
              placeholder="Find tag..."
              value={search}
              onChange={onChangeSearch}
            />
          </div>
          <div className={styles.listContainer}>
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <PulseLoader color={"#7D4AFB"} />
              </div>
            )}
            {!isLoading &&
              tags.map((item, index) => (
                <EditableTag
                  key={index}
                  title={item.value}
                  withCounter={true}
                  counter={item?.usage}
                  onGetTags={onGetTags}
                  maxLength={30}
                />
              ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default Tags;
