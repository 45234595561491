import Navigation from "./navigation";
import { Toaster } from "react-hot-toast";

import { CheckCircleIcon, CrossCircleIcon } from "./assets";

function App() {
  return (
    <>
      <Navigation />
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "rgba(213, 244, 211, 0.8)",
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 22,
              paddingRight: 22,
              borderRadius: 10,
              fontSize: 14,
              fontFamily: "Inter",
              textAlign: "left",
              maxWidth: 1000,
            },
            icon: <CheckCircleIcon />,
          },
          error: {
            style: {
              background: "rgba(254, 206, 206, 0.8)",
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 22,
              paddingRight: 22,
              borderRadius: 10,
              fontSize: 14,
              fontFamily: "Inter",
              textAlign: "left",
              maxWidth: 1000,
            },
            icon: <CrossCircleIcon />,
          },
        }}
      />
    </>
  );
}

export default App;
