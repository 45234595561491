import { FC, useEffect, useMemo, useRef, useState } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { add, differenceInCalendarDays, format } from "date-fns";
import { ArrowSmallDownIcon, ArrowSmallLineUpIcon } from "../../assets";
import { motion } from "framer-motion";
import { getDaysOfWeek } from "../../utils";
import { getUserWeeklyStats } from "../../services/users.service";
import { PulseLoader } from "react-spinners";
import clsx from "clsx";
import dayjs from "dayjs";

const WeeklyChart: FC<IProps> = ({ userInfo }): JSX.Element => {
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [indicatorOffset, setIndicatorOffset] = useState(0);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [daysInWeek, setDaysInWeek] = useState<string[]>(
    getDaysOfWeek(selectedDate)
  );

  const [activeTab, setSelectedTab] = useState<1 | 0>(0);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [data, setData] = useState<any>();

  const onGetData = async () => {
    try {
      setIsLoadingData(true);
      const { data } = await getUserWeeklyStats(
        userInfo.id,
        selectedDate.toISOString()?.split("T")[0]
      );
      if (data?.success) {
        setData(data?.data);
      }
    } catch (error) {
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    onGetData();
  }, [selectedDate]);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDaysInWeek(getDaysOfWeek(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    setIndicatorWidth((ref.current?.offsetWidth || 0) / 2);
    setIndicatorOffset(((ref.current?.offsetWidth || 0) / 2) * activeTab);
  }, [ref.current, activeTab]);

  const onPrevWeek = () => {
    setSelectedDate(add(selectedDate, { weeks: -1 }));
  };

  const onNextWeek = () => {
    setSelectedDate(add(selectedDate, { weeks: 1 }));
  };

  const maxValue = useMemo(() => {
    let max = 0;
    if (data && data?.statistic && data?.statistic?.length) {
      data.statistic.forEach((item: any) => {
        if (item.deficit > max) {
          max = item.deficit;
        }
      });
      return max;
    }
    return 0;
  }, [data]);

  const minValue = useMemo(() => {
    let min = 0;
    if (data && data?.statistic && data?.statistic?.length) {
      data.statistic.forEach((item: any) => {
        if (item.deficit < min) {
          min = item.deficit;
        }
        if (item.target < min) {
          min = item.target;
        }
      });
      return min;
    }
    return 0;
  }, [data]);

  const generateTopYAxisValues = () => {
    if (maxValue === 0) {
      return [];
    }
    let step = (maxValue + 600) / 2;

    return Array(3)
      .fill("")
      .map((_, index) => step * index);
  };

  const generateYAxisValues = () => {
    let step = (minValue - 600) / (maxValue === 0 ? 5 : 3);

    let yAxis = Array(maxValue === 0 ? 6 : 4)
      .fill("")
      .map((_, index) => step * index);

    if (maxValue > 0) {
      const minusYAxis = generateTopYAxisValues();

      yAxis = [minusYAxis[2], minusYAxis[1], ...yAxis];
    }
    return yAxis;
  };

  return (
    <div ref={ref} className={styles.wrapper}>
      <div className={styles.dateHeader}>
        <div onClick={onPrevWeek} className={styles.dateHeaderButton}>
          <ArrowSmallDownIcon className={styles.leftArrow} />
        </div>
        <p className={styles.dateTitle}>
          {format(dayjs.utc(daysInWeek[0]).toDate(), "MMM dd")} -{" "}
          {format(dayjs.utc(daysInWeek[6]).toDate(), "MMM dd, yyyy")}
        </p>
        <div onClick={onNextWeek} className={styles.dateHeaderButton}>
          <ArrowSmallDownIcon className={styles.rightArrow} />
        </div>
      </div>
      <div className={styles.rangeSwitcher}>
        <div onClick={() => setSelectedTab(0)} className={styles.rangeButton}>
          <span className={styles.rangeButtonValue}>
            {data?.avgDeficit > 0 && "+"}
            {""}
            {Math.abs(data?.avgDeficit || 0)?.toLocaleString(undefined, {
              maximumFractionDigits: 0,
            }) || 0}{" "}
            <span className={styles.rangeButtonUnit}>cal</span>
            {!!data?.percents && (
              <div
                className={clsx(styles.percentsContainer, {
                  [styles.percentsContainerRed]: data?.percents < 0,
                })}
              >
                <ArrowSmallLineUpIcon
                  className={clsx(styles.arrow, {
                    [styles.arrowRef]: data?.percents < 0,
                  })}
                />
                <span className={styles.percentValue}>
                  {Math.abs(data?.percents)}%
                </span>
              </div>
            )}
          </span>
          <span className={styles.rangeButtontitle}>DAILY AVERAGE DEFICIT</span>
        </div>
        <div onClick={() => setSelectedTab(1)} className={styles.rangeButton}>
          <span className={styles.rangeButtonValue}>
            {data?.accumulatedDeficit > 0 && "+"}
            {""}
            {Math.abs(data?.accumulatedDeficit || 0)?.toLocaleString(
              undefined,
              {
                maximumFractionDigits: 0,
              }
            ) || 0}{" "}
            <span className={styles.rangeButtonUnit}>cal</span>
          </span>
          <span className={styles.rangeButtontitle}>
            ACCUMULATED DEFICIT (1W)
          </span>
        </div>
        <motion.div
          animate={{ x: indicatorOffset }}
          transition={{ type: "keyframes" }}
          style={{ width: indicatorWidth }}
          className={styles.rangeIndicator}
        />
      </div>
      {isLoadingData || !data ? (
        <div className={styles.loaderWrapper}>
          <PulseLoader color={"#612aff"} />
        </div>
      ) : (
        <div className={styles.chartWrapper}>
          <div className={styles.yAxisContainer}>
            {generateYAxisValues().map((item, index) => (
              <div key={index} className={styles.yAxisText}>{item.toFixed(0)}</div>
            ))}
          </div>
          <div className={styles.gridContainer}>
            <div className={styles.gridLine} />
            <div className={styles.gridLine} />
            <div className={styles.gridLine} />
            <div className={styles.gridLine} />
            <div className={styles.gridLine} />
            <div className={styles.gridLine} />
          </div>
          <div className={styles.xAxisContainer}>
            {daysInWeek.map((item, index) => (
              <div className={styles.xAxisText} key={index}>
                {format(new Date(item), "LLL dd")}
              </div>
            ))}
          </div>
          <div
            style={{
              bottom:
                maxValue > 0 ? (minValue < 0 ? 89 : 0) : minValue > 0 ? 89 : 0,
            }}
            className={styles.topChartContainer}
          >
            {daysInWeek.map((item, index) => {
              const indexInStats = data?.statistic?.findIndex(
                (el: any) => el.date === item
              );
              let barItemDeficitHeight = 0;
              let targetDeficitHeight = 0;
              let mainDeficitHeight = 0;

              if (indexInStats !== -1) {
                const daystats = data?.statistic[indexInStats];

                targetDeficitHeight =
                  (Math.abs(daystats?.target) / Math.abs(minValue - 600)) * 100;
                barItemDeficitHeight =
                  (Math.abs(daystats?.deficit) / Math.abs(minValue - 600)) *
                  100;

                if (Math.abs(daystats?.deficit) > Math.abs(daystats?.target)) {
                  mainDeficitHeight =
                    (Math.abs(daystats?.target) / Math.abs(daystats?.deficit)) *
                    100;
                } else {
                  mainDeficitHeight = 100;
                }
                if (daystats?.deficit > 0) {
                  return (
                    <div className={styles.topBarItemWrapper} key={index}>
                      <div
                        style={{ height: `${targetDeficitHeight}%` }}
                        className={styles.targetBarItemWrapper}
                      >
                        <div className={styles.targetBarItem} />
                      </div>
                    </div>
                  );
                }
              }
              return (
                <div className={styles.topBarItemWrapper} key={index}>
                  <div className={styles.targetBarItem} />
                  {targetDeficitHeight > 0 && (
                    <div
                      style={{ height: `${targetDeficitHeight}%` }}
                      className={styles.targetBarItemWrapper}
                    >
                      <div className={styles.targetBarItem} />
                    </div>
                  )}
                  <div
                    style={{
                      width: "100%",
                      height: `${barItemDeficitHeight}%`,
                      alignItems: "center",
                      justifyContent: "flex-end",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        borderTopRightRadius: 3,
                        borderTopLeftRadius: 3,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        overflow: "hidden",
                        width: "30px",
                        height: `${100 - mainDeficitHeight}%`,
                        marginBottom: "-2px",
                      }}
                    >
                      <div className={styles.superDeficitContainer}>
                        {Array(100)
                          .fill(" ")
                          .map((_, index) => (
                            <div
                              key={index}
                              style={{
                                backgroundColor:
                                  index % 2 === 0
                                    ? "#612AFF"
                                    : "rgba(97, 42, 255, 0.2)",
                              }}
                              className={styles.superDeficitLine}
                            />
                          ))}
                      </div>
                    </div>
                    <div
                      className={styles.topBarItem}
                      style={{
                        height: `${mainDeficitHeight}%`,
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.bottomChartContainer}>
            {daysInWeek.map((item, index) => {
              const indexInStats = data?.statistic?.findIndex(
                (el: any) => el.date === item
              );

              let barItemDeficitHeight = 0;

              if (indexInStats !== -1) {
                const daystats = data?.statistic[indexInStats];

                if (daystats?.deficit < 0) {
                  return (
                    <div
                      className={styles.bottomBarItemWrapper}
                      key={index}
                    ></div>
                  );
                }
                // if (daystats?.deficit < 0) {
                //   return (
                //     <TouchableOpacity
                //       onPress={() =>
                //         setActiveTooltipIndex(prev =>
                //           prev === format(new Date(item), 'yyyy-M-d')
                //             ? null
                //             : format(new Date(item), 'yyyy-M-d'),
                //         )
                //       }
                //       disabled={indexInStats === -1}
                //       style={styles.bottomBarItemWrapper}
                //       key={index}
                //     />
                //   );
                // }

                barItemDeficitHeight =
                  (Math.abs(daystats?.deficit) / Math.abs(maxValue + 600)) *
                  100;
              }

              return (
                <div className={styles.bottomBarItemWrapper} key={index}>
                  <div
                    style={{ height: `${barItemDeficitHeight}%` }}
                    className={styles.bottomBarItem}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className={styles.footerChart}>
        <div className={styles.footerChartItem}>
          <div
            style={{ backgroundColor: "#612AFF", borderColor: "#612AFF" }}
            className={styles.footerChartItemIndicator}
          />
          <span className={styles.footerChartItemTitle}>Deficit</span>
        </div>

        <div className={styles.footerChartItem}>
          <div
            style={{ backgroundColor: "#FF2950", borderColor: "#FF2950" }}
            className={styles.footerChartItemIndicator}
          />
          <span className={styles.footerChartItemTitle}>Surplus</span>
        </div>

        <div className={styles.footerChartItem}>
          <div
            style={{
              backgroundColor: "rgba(97, 42, 255, 0.2)",
              borderColor: "rgba(97, 42, 255, 0.2)",
            }}
            className={styles.footerChartItemIndicator}
          />
          <span className={styles.footerChartItemTitle}>Target</span>
        </div>

        <div className={styles.footerChartItem}>
          <div className={styles.footerChartItemIndicatorSuperDeficit}>
            <div className={styles.primary} />
            <div className={styles.opacity} />
          </div>
          <span className={styles.footerChartItemTitle}>Super deficit</span>
        </div>
      </div>
    </div>
  );
};

export default WeeklyChart;
