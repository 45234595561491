import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { CrossIcon, UserIcon } from "../../assets";
import { Button, Input, Selector } from "../../components";
import { StaffRole } from "../../constants";
import { getLinkForUploadImage } from "../../services/auth.service";
import { uploadFile } from "../../services/file.service";
import { createStaff } from "../../services/staff.service";
import styles from "./styles.module.scss";
import { toast } from "react-hot-toast";
import { Formik } from "formik";
import { createStaffValidationScheme } from "./validation";
interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onGetAllStaff: () => void;
}

const AddNewStaff: FC<Props> = ({
  isOpen,
  setIsOpen,
  onGetAllStaff,
}): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [role, setRole] = useState<string>("Moderator");
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<string>("");
  const [avatarFile, setAvatarFile] = useState<
    ArrayBuffer | null | undefined | string | any
  >();
  const [avatarFileName, setAvatarFileName] = useState<string>("");

  //error
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [fullNameError, setFullNameError] = useState<string>("");

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onChangeFullName = (e: ChangeEvent<HTMLInputElement>) => {
    setFullName(e.target.value);
  };

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (
      email?.length > 3 &&
      password &&
      fullName.length > 1 &&
      fullName.length <= 50
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [email, password, fullName]);

  const onCreateStaff = async (
    email: string,
    password: string,
    fullName: string,
    setFieldError: (
      field: "email" | "password" | "fullName",
      message: string
    ) => void
  ) => {
    try {
      setIsLoading(true);
      let imageLink = "";
      if (avatarFile) {
        //@ts-ignore
        const resLinkImg = await getLinkForUploadImage(avatarFileName);
        imageLink = resLinkImg?.data?.data?.downloadUrl;
        let blob = await fetch(avatar).then((r) => r.blob());
        const resUploadImg = await uploadFile(
          blob,
          resLinkImg?.data?.data?.uploadUrl
        );
      }

      const { data } = await createStaff(
        email.trim(),
        password.trim(),
        fullName.trim(),
        role,
        imageLink
      );
      if (data?.success) {
        onGetAllStaff();
        setIsOpen(false);
        setEmailError("");
        setPasswordError("");
        setFullNameError("");
        setEmail("");
        setFullName("");
        setPassword("");
        setAvatar("");
        setAvatarFile(undefined);
        setAvatarFileName("");
        toast.success("User was successfully created");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      if (error?.field === "email") {
        setFieldError("email", error?.message);
      } else {
        setFieldError("email", "");
      }
      if (error?.field === "password") {
        setFieldError("password", error?.message);
      } else {
        setFieldError("password", "");
      }
      if (error?.field === "fullName") {
        setFieldError("fullName", error?.message);
      } else {
        setFieldError("fullName", "");
      }
    }
  };

  useEffect(() => {
    setEmailError("");
    setPasswordError("");
    setFullNameError("");
    setEmail("");
    setFullName("");
    setPassword("");
    setAvatar("");
    setAvatarFile(undefined);
    setAvatarFileName("");
  }, [isOpen]);

  const inputFile = useRef<HTMLInputElement>(null);

  const showOpenFileDialog = () => {
    inputFile.current?.click();
  };

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      console.log("e", e);
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }

      setAvatar(URL.createObjectURL(e.target.files[0]));
      let file = e.target.files[0];
      setAvatarFile(e.target.files[0]);

      setAvatarFileName(file?.name);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <h5 className={styles.title}>New Manager</h5>
        <CrossIcon
          onClick={() => setIsOpen(false)}
          className={styles.closeBtn}
        />
        <div className={styles.header}>
          <div onClick={showOpenFileDialog} className={styles.avatarBtn}>
            {avatar && (
              <img src={avatar} alt={"avatar"} className={styles.avatar} />
            )}
            {!avatar && <UserIcon />}
            {/* <img src={AvatarIcon} alt={} /> */}
          </div>

          <input
            style={{ display: "none" }}
            ref={inputFile}
            type={"file"}
            accept="image/*"
            onChange={onSelectFile}
            onClick={(e: any) => (e.target.value = null)}
            className={styles.bootcampsSettingsUploadImgBtn}
          />
          <p className={styles.avatarTitle}>Change Avatar</p>
        </div>
        <Formik
          validationSchema={createStaffValidationScheme}
          validateOnBlur={false}
          validateOnChange={true}
          initialValues={{ fullName: "", email: "", password: "" }}
          onSubmit={(values, { setFieldError }) => {
            onCreateStaff(
              values.email,
              values.password,
              values.fullName,
              setFieldError
            );
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
            setTouched,
          }) => {
            return (
              <>
                <div className={styles.inputsContainer}>
                  <Input
                    label="Full Name"
                    value={values.fullName}
                    onChange={handleChange("fullName")}
                    onBlur={handleBlur("fullName")}
                    placeholder="Full Name"
                    autoComplete="new-password"
                    error={touched.fullName ? errors.fullName : ""}
                  />
                  <Input
                    label="Email"
                    value={values.email}
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                    placeholder="example@gmail.com"
                    autoComplete="new-password"
                    error={touched.email ? errors.email : ""}
                  />
                  <Input
                    label="Password"
                    value={values.password}
                    onChange={handleChange("password")}
                    onBlur={handleBlur("password")}
                    placeholder="password"
                    type={"password"}
                    autoComplete="new-password"
                    error={touched.password ? errors.password : ""}
                  />
                  <Selector
                    label="Role"
                    value={role}
                    setValue={setRole}
                    data={StaffRole}
                    disabled={true}
                  />
                </div>
                <Button
                  title="Create"
                  styleType={"filled"}
                  loading={isLoading}
                  onClick={() => handleSubmit()}
                  size="small"
                />
              </>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddNewStaff;
