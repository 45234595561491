import { FC } from "react";
import styles from "./styles.module.scss";
import PlaceholderAvatar from "../../assets/images/avatar.png";

interface Props {
  image?: string | null;
  title?: string;
  onClick?: () => void;
}

const UserPreview: FC<Props> = ({
  image,
  title,
  onClick = () => {},
}): JSX.Element => {
  return (
    <div onClick={onClick} className={styles.wrapper}>
      <img
        src={image ? image : PlaceholderAvatar}
        alt={"avatar"}
        className={styles.avatar}
      />
      <p className={styles.name}>{title}</p>
    </div>
  );
};

export default UserPreview;
