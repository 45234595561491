import { FC } from "react";
import Modal from "react-modal";
import styles from "./styles.module.scss";
import { CrossIcon, GroupIcon, LikeLinearIcon } from "../../assets";
import Image from "../../assets/images/blockchain/img.png";
import { CommentItem } from "../../components";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const UserPost: FC<Props> = ({ isOpen, setIsOpen }): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <div onClick={() => setIsOpen(false)} className={styles.closeButton}>
          <CrossIcon />
        </div>
        <div className={styles.imageContainer}>
          <GroupIcon />
        </div>
        <div className={styles.commentContainer}>
          <div className={styles.postBlock}>
            <div className={styles.userBlock}>
              <div className={styles.avatar} />
              <div>
                <div className={styles.userName}>test test</div>
                <div className={styles.date}>2 min ago</div>
              </div>
            </div>
            <div className={styles.description}>
              I’m trying to workout as best as I can. What do you guys think
              about it?
            </div>
            <div className={styles.iconItem}>
              <LikeLinearIcon />
              <span>20</span>
            </div>
          </div>
          <div className={styles.postDivider} />
          <span className={styles.sectionTitle}>Comments (13)</span>
          <CommentItem />
          <CommentItem />
        </div>
      </div>
    </Modal>
  );
};

export default UserPost;
