import clsx from "clsx";
import React, { ButtonHTMLAttributes, FC, ReactNode } from "react";
import styles from "./styles.module.scss";
import ClipLoader from "react-spinners/ClipLoader";

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  loading?: boolean;
  styleType:
    | "filled"
    | "outlined"
    | "outlined-dashed"
    | "inactive"
    | "disabled";
  size: "mobile" | "x-small" | "small" | "medium" | "large";
  color?: string;
  backgroundColor?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  disabled?: boolean;
  textPosition?: "center" | "flex-start";
  isShowBorder?: boolean;
  customStyles?: any;
}

const Button: FC<IProps> = ({
  title,
  size = "small",
  loading = false,
  styleType = "filled",
  backgroundColor = "",
  color = "",
  leftIcon,
  rightIcon,
  disabled = false,
  textPosition = "center",
  isShowBorder = true,
  customStyles = {},
  ...props
}): JSX.Element => {
  return (
    <button
      disabled={loading || disabled}
      style={{
        background: backgroundColor,
        color: color,
        justifyContent: textPosition,
        borderColor: isShowBorder ? color : "transparent",
        boxSizing: "border-box",
      }}
      className={clsx(
        {
          [styles.filled]: styleType === "filled",
          [styles.outlined]: styleType === "outlined",
          [styles.outlinedDashed]: styleType === "outlined-dashed",
          [styles.inactive]: styleType === "inactive",
          [styles.xSmall]: size === "x-small",
          [styles.small]: size === "small",
          [styles.medium]: size === "medium",
          [styles.large]: size === "large",
          [styles.mobile]: size === "mobile",
          [styles.disabled]: disabled,
        },
        customStyles
      )}
      {...props}
    >
      {loading ? (
        <ClipLoader
          color={styleType === "disabled" ? "#000" : "#fff"}
          loading={loading}
          size={20}
        />
      ) : (
        <div className={styles.titleWrapper}>
          {leftIcon && leftIcon} {title} {rightIcon && rightIcon}
        </div>
      )}
    </button>
  );
};

export default Button;
