import React, { FC } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";

const Tag: FC<IProps> = ({
  title,
  withCounter = false,
  counter = 0,
}): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <span>{title}</span>
      {/* {withCounter && <div className={styles.counterWrapper}></div>} */}
    </div>
  );
};

export default Tag;
