import React, { FC, useEffect, useState } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowDownIcon, ArrowSmallDownIcon } from "../../assets";
import { Button, CheckBox } from "..";
import clsx from "clsx";
import { getTags } from "../../services/recipe.service";
import { getCategories } from "../../services/category.service";

interface ITags {
  value: string;
  usage: number;
}

const Genders = ["Male", "Female"];

const UserFilter: FC<IProps> = ({
  setIsOpen,
  filterGender,
  setFilterGender,
}): JSX.Element => {
  const [isShowGenderList, setIsShowGenderList] = useState(false);

  return (
    <motion.div
      initial={{ scale: 0.8, opacity: 0.5 }}
      animate={{ scale: 1, opacity: 1 }}
      className={styles.wrapper}
    >
      <h5 className={styles.title}>Filter</h5>
      <div className={styles.sectionDevider} />
      <div
        onClick={() => setIsShowGenderList((prev) => !prev)}
        className={styles.sectionHeader}
      >
        <span className={styles.sectionTitle}>Gender</span>
        <ArrowDownIcon
          className={clsx({
            [styles.arrowOpen]: isShowGenderList,
            [styles.arrowClosed]: !isShowGenderList,
          })}
        />
      </div>
      <AnimatePresence>
        {isShowGenderList && (
          <motion.div
            initial={{ height: 0, marginTop: 0 }}
            animate={{ height: 52, marginTop: 10 }}
            exit={{ height: 0, marginTop: 0 }}
            className={styles.listContainer}
          >
            {Genders.map((item, index) => {
              const isChecked = filterGender.includes(item);
              const onSelect = () => {
                if (isChecked) {
                  setFilterGender(filterGender.filter((el) => el !== item));
                } else {
                  setFilterGender([...filterGender, item]);
                }
              };

              return (
                <div key={index} className={styles.selectorItem}>
                  <CheckBox isChecked={isChecked} setIsChecked={onSelect} />
                  <span
                    style={{ color: isChecked ? "#11142D" : "#808191" }}
                    className={styles.selectorItemText}
                  >
                    {item}
                  </span>
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>

      <div className={styles.sectionDevider} />

      <div
        onClick={() => {
          setFilterGender([]);
        }}
        className={styles.resetButton}
      >
        <div>Reset all filters</div>
      </div>
    </motion.div>
  );
};

export default UserFilter;
