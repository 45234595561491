import { FC } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import {
  CalorieDeficit,
  ConsistencyStreakCalendar,
  MealPlanCalendar,
  WeightLossGoal,
} from "../../../../../components";

const ProgressTab: FC<IProps> = ({ userInfo }): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.leftContainer}>
        <CalorieDeficit userInfo={userInfo} />
        <div className={styles.sectionDivider} />
        <WeightLossGoal userInfo={userInfo} />
        <div className={styles.sectionDivider} />
        <ConsistencyStreakCalendar userInfo={userInfo} />
      </div>
      <div className={styles.divider} />
      <MealPlanCalendar userInfo={userInfo} />
    </div>
  );
};

export default ProgressTab;
