import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import {
  Button,
  PaginationButtons,
  ReportFilter,
  ReportItem,
  SearchInput,
  UserFilter,
  UserItem,
} from "../../../components";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { ArrowSmallDownIcon, FilterIcon } from "../../../assets";
import { PulseLoader } from "react-spinners";
import { useDebounce, useOutsideClick } from "../../../hooks";
import { getReports } from "../../../services/report.service";

const reportsLimit = 20;

const Reports: FC = (): JSX.Element => {
  const [search, setSearch] = useState("");
  const filterRef = useRef<HTMLDivElement>(null);
  const debounceSearch = useDebounce(search, 400);

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagesCount, setPagesCount] = useState(0);
  const [page, setPage] = useState(1);
  const [reports, setReports] = useState([1, 2, 3, 44]);
  const [filterTypes, setFilterTypes] = useState<string[]>([]);
  const [filterCategories, setFilterCategories] = useState<string[]>([]);
  const [fromSort, setFromSort] = useState(0);
  const [typeSort, setTypeSort] = useState(0);
  const [toSort, setToSort] = useState(0);
  const [dateSort, setDateSort] = useState(0);
  const [categorySort, setCategorySort] = useState(0);

  useOutsideClick(filterRef, () => setIsOpenFilter(false));

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetReports = async () => {
    try {
      setIsLoading(true);
      const { data } = await getReports(
        debounceSearch,
        page,
        reportsLimit,
        filterCategories,
        filterTypes,
        fromSort !== 0
          ? "from"
          : typeSort !== 0
          ? "type"
          : toSort !== 0
          ? "to"
          : dateSort !== 0
          ? "createdAt"
          : categorySort !== 0
          ? "category"
          : null,
        fromSort !== 0
          ? fromSort
          : typeSort !== 0
          ? typeSort
          : toSort !== 0
          ? toSort
          : dateSort !== 0
          ? dateSort
          : categorySort !== 0
          ? categorySort
          : null
      );
      if (data?.success) {
        setReports(data?.data?.reports);
        setPagesCount(Math.ceil(data?.data?.count / reportsLimit));
        setPage(data?.data?.page);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetReports();
  }, [
    debounceSearch,
    page,
    filterCategories,
    filterTypes,
    fromSort,
    typeSort,
    toSort,
    dateSort,
    categorySort,
  ]);

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <h4 className={styles.title}>Reports</h4>
        <div className={styles.headerAddButton}>
          {/* <Button
          onClick={() => navigation("/")}
          title={"Create New Meal"}
          leftIcon={<AddSquareIcon />}
          styleType="filled"
          size="small"
        /> */}
        </div>
      </header>

      <section className={styles.contentContainer}>
        <div className={styles.contentContainerHeader}>
          <div className={styles.searchContainer}>
            <SearchInput
              placeholder="Search"
              value={search}
              onChange={onChangeSearch}
            />
          </div>
          <div ref={filterRef}>
            <div
              onClick={() => setIsOpenFilter((prev) => !prev)}
              className={clsx(styles.filterButton, {
                [styles.activeFilterButton]: isOpenFilter,
              })}
            >
              <FilterIcon />
            </div>
            {isOpenFilter && (
              <ReportFilter
                setIsOpen={setIsOpenFilter}
                filterTypes={filterTypes}
                setFilterTypes={setFilterTypes}
                filterCategories={filterCategories}
                setFilterCategories={setFilterCategories}
              />
            )}
          </div>
        </div>

        <div className={styles.sectionHeader}>
          <div
            onClick={() => {
              setDateSort(0);
              setTypeSort(0);
              setToSort(0);
              setCategorySort(0);
              setFromSort((prev) => {
                if (prev === 0) {
                  return -1;
                } else if (prev === -1) {
                  return 1;
                } else return 0;
              });
            }}
            className={clsx(styles.reporterBlock, {
              [styles.activeSortValue]: fromSort !== 0,
            })}
          >
            <p>Reporter</p>{" "}
            <ArrowSmallDownIcon
              className={clsx(styles.sectionHeaderArrow, {
                [styles.sectionHeaderArrowUp]: fromSort === -1,
              })}
            />
          </div>
          <div
            onClick={() => {
              setDateSort(0);
              setFromSort(0);
              setToSort(0);
              setCategorySort(0);
              setTypeSort((prev) => {
                if (prev === 0) {
                  return -1;
                } else if (prev === -1) {
                  return 1;
                } else return 0;
              });
            }}
            className={clsx(styles.typeBlock, {
              [styles.activeSortValue]: typeSort !== 0,
            })}
          >
            <p>Type</p>{" "}
            <ArrowSmallDownIcon
              className={clsx(styles.sectionHeaderArrow, {
                [styles.sectionHeaderArrowUp]: typeSort === -1,
              })}
            />
          </div>
          <div
            onClick={() => {
              setDateSort(0);
              setTypeSort(0);
              setToSort(0);
              setFromSort(0);
              setCategorySort((prev) => {
                if (prev === 0) {
                  return -1;
                } else if (prev === -1) {
                  return 1;
                } else return 0;
              });
            }}
            className={clsx(styles.categoryBlock, {
              [styles.activeSortValue]: categorySort !== 0,
            })}
          >
            <p>Category</p>{" "}
            <ArrowSmallDownIcon
              className={clsx(styles.sectionHeaderArrow, {
                [styles.sectionHeaderArrowUp]: categorySort === -1,
              })}
            />
          </div>
          <div
            onClick={() => {
              setDateSort(0);
              setTypeSort(0);
              setFromSort(0);
              setCategorySort(0);
              setToSort((prev) => {
                if (prev === 0) {
                  return -1;
                } else if (prev === -1) {
                  return 1;
                } else return 0;
              });
            }}
            className={clsx(styles.reportedOnBlock, {
              [styles.activeSortValue]: toSort !== 0,
            })}
          >
            <p>Reported On</p>{" "}
            <ArrowSmallDownIcon
              className={clsx(styles.sectionHeaderArrow, {
                [styles.sectionHeaderArrowUp]: toSort === -1,
              })}
            />
          </div>
          <div
            onClick={() => {
              setTypeSort(0);
              setToSort(0);
              setCategorySort(0);
              setFromSort(0);
              setDateSort((prev) => {
                if (prev === 0) {
                  return -1;
                } else if (prev === -1) {
                  return 1;
                } else return 0;
              });
            }}
            className={clsx(styles.dateBlock, {
              [styles.activeSortValue]: dateSort !== 0,
            })}
          >
            <p>Report date</p>{" "}
            <ArrowSmallDownIcon
              className={clsx(styles.sectionHeaderArrow, {
                [styles.sectionHeaderArrowUp]: dateSort === -1,
              })}
            />
          </div>
        </div>

        <div className={styles.listContainer}>
          {isLoading ? (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#7D4AFB"} />
            </div>
          ) : (
            reports.map((item, index) => (
              <ReportItem key={index} item={item} onGetReports={onGetReports} />
            ))
          )}
        </div>
        <PaginationButtons
          pagesCount={pagesCount}
          page={page}
          onPress={(index) => setPage(index)}
        />
      </section>
    </div>
  );
};

export default Reports;
