import { FC, useEffect, useRef, useState } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { motion } from "framer-motion";
import clsx from "clsx";
import DailyChart from "../DailyChart";
import WeeklyChart from "../WeeklyChart";
import HalfYearChart from "../HalfYearChart";
import YearlyChart from "../YearlyChart";
import MonthlyChart from "../MonthlyChart";

const RANGE_BUTTONS = ["1D", "1W", "1M", "6M", "1Y"];

const CaloriesDeficit: FC<IProps> = ({ userInfo }): JSX.Element => {
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [indicatorOffset, setIndicatorOffset] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [activeRange, setActiveRange] = useState(0);

  useEffect(() => {
    const SWITCHER_WIDTH = (ref.current?.offsetWidth || 8) - 8;
    setIndicatorWidth(SWITCHER_WIDTH / RANGE_BUTTONS.length);

    setIndicatorOffset((SWITCHER_WIDTH / RANGE_BUTTONS.length) * activeRange);
  }, [ref.current, activeRange]);

  return (
    <div ref={ref} className={styles.wrapper}>
      <h5 className={styles.title}>Calorie Deficit</h5>
      <div className={styles.rangeSwitcher}>
        {RANGE_BUTTONS.map((item, index) => (
          <div
            key={index}
            onClick={() => setActiveRange(index)}
            className={clsx(styles.rangeButton, {
              [styles.rangeButtonActive]: activeRange === index,
            })}
          >
            {item}
          </div>
        ))}
        <motion.div
          animate={{ x: indicatorOffset }}
          transition={{ type: "keyframes" }}
          style={{ width: indicatorWidth }}
          className={styles.rangeIndicator}
        />
      </div>
      <div className={styles.chartWrapper}>
        {activeRange === 0 && <DailyChart userInfo={userInfo} />}
        {activeRange === 1 && <WeeklyChart userInfo={userInfo} />}
        {activeRange === 2 && <MonthlyChart userInfo={userInfo} />}
        {activeRange === 3 && <HalfYearChart userInfo={userInfo} />}
        {activeRange === 4 && <YearlyChart userInfo={userInfo} />}
      </div>
    </div>
  );
};

export default CaloriesDeficit;
