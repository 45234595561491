import React, { FC } from "react";
import { IProps } from "./types.";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { ArrowSmallDownIcon } from "../../../../../assets";
import { PostItem } from "../../../../../components";

const PostsTab: FC<IProps> = ({ userId }): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.sectionHeader}>
        <div
          onClick={() => {}}
          className={clsx(styles.nameBlock, {
            [styles.activeSortValue]: false,
          })}
        >
          <p>User</p>{" "}
          <ArrowSmallDownIcon
            className={clsx(styles.sectionHeaderArrow, {
              [styles.sectionHeaderArrowUp]: false,
            })}
          />
        </div>

        <div
          onClick={() => {}}
          className={clsx(styles.dateBlock, {
            // [styles.dateBlockActive]: sort === 1,
          })}
        >
          <p>Registration date</p> <ArrowSmallDownIcon />
        </div>
      </div> */}
      <div className={styles.postsList}>
        {[1, 2, 2, 2].map((item, index) => (
          <PostItem key={index} />
        ))}
      </div>
    </div>
  );
};

export default PostsTab;
