import { rejects } from "assert";
import axiosInstance from "../apis";
import { AxiosResponse } from "axios";

export const getTags = (search: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params = {
      value: search,
    };
    axiosInstance
      .get("recipes/tags", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const createTags = (name: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      value: name,
    };
    axiosInstance
      .post("recipes/tags", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const updateTags = (
  oldValue: string,
  newValue: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      newValue,
      oldValue,
    };

    axiosInstance
      .put("recipes/tags", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const deleteTags = (tag: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`recipes/tags/${tag}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getRecipes = (
  page: number,
  limit: number,
  search: string,
  categories: string[],
  tags: string[],
  sortValue?: "name" | "category" | null,
  order?: -1 | 1 | null
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params: any = {
      page,
      limit,
      value: search,
    };

    if (categories.length > 0) {
      params.categories = categories.join(",");
    }

    if (tags.length > 0) {
      params.tags = tags.join(",");
    }

    if (sortValue) {
      params.sortValue = sortValue;
    }

    if (order) {
      params.order = order;
    }

    axiosInstance
      .get("recipes/search", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getRecipeById = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`recipes/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

interface ICreateRecipeIngredient {
  ingredient_id: string;
  unit: string;
  count: number;
}

export const getLinkForUploadRecipeImage = (
  fileName: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      fileName,
    };
    axiosInstance
      .post("recipes/image", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const createRecipe = (
  name: string,
  tags: string[],
  category: string,
  method: string[],
  ingredients: ICreateRecipeIngredient[],
  description: string,
  image: string,
  numberOfServes: string,
  gramsPerServe: string,
  gramsPerServeUnit: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let body: any = {
      name,
      tags,
      category,
      ingredients,
      numberOfServes: +numberOfServes,
    };
    if (description) {
      body.description = description;
    }
    if (method?.length) {
      body.method = method;
    }
    if (image) {
      body.image = image;
    }
    if (gramsPerServe) {
      body.gramsPerServe = +gramsPerServe;
    }
    if (gramsPerServeUnit) {
      body.gramsPerServeUnit = gramsPerServeUnit;
    }
    axiosInstance
      .post("recipes", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const updateRecipe = (
  name: string,
  tags: string[],
  category: string,
  method: string[],
  ingredients: ICreateRecipeIngredient[],
  description: string,
  image: string,
  recipe_id: string,
  numberOfServes: string,
  gramsPerServe: string,
  gramsPerServeUnit: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let body: any = {
      name,
      tags,
      category,
      ingredients,
      recipe_id,
      image,
      numberOfServes: +numberOfServes,
    };
    if (description) {
      body.description = description;
    }
    if (method?.length) {
      body.method = method;
    }
    if (gramsPerServe) {
      body.gramsPerServe = +gramsPerServe;
    }
    if (gramsPerServeUnit) {
      body.gramsPerServeUnit = gramsPerServeUnit;
    }

    axiosInstance
      .put("recipes", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const deleteRecipe = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`recipes/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
