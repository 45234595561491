import axiosInstance from "../apis";
import axios, { AxiosResponse } from "axios";
import { GOOGLE_API_KEY, GOOGLE_SEARCH_ENGINE } from "../constants";

export const getGoogleImages = (
  q: string,
  start: number
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params = {
      key: GOOGLE_API_KEY,
      cx: GOOGLE_SEARCH_ENGINE,
      searchType: "image",
      q,
      start,
      imgSize: "large",
    };
    axios
      .get("https://www.googleapis.com/customsearch/v1", {
        params,
        headers: {
          Authorization: "",
        },
      })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
