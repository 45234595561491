import React, { FC } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";

const MacroIconItem: FC<IProps> = ({
  title,
  icon,
  iconBackgroundCOlor,
  iconColor,
  value,
}): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div
        style={{ backgroundColor: iconBackgroundCOlor }}
        className={styles.iconWrapper}
      >
        {icon}
      </div>
      <span className={styles.value}>{value}</span>
      <span className={styles.title}>{title}</span>
    </div>
  );
};

export default MacroIconItem;
