import { AxiosResponse } from "axios";
import axiosInstance from "../apis";
import { IUnit } from "../models";

export const getIngredients = (
  page: number,
  limit: number,
  search: string,
  sortValue?: "name" | "category" | "createdAt" | "updatedAt" | null,
  order?: -1 | 1 | null,
  categories?: string[]
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params: any = {
      page,
      limit,
      value: search,
    };

    if (sortValue) {
      params.sortValue = sortValue;
    }

    if (order) {
      params.order = order;
    }

    if (categories) {
      params.categories = categories?.join("|");
    }

    axiosInstance
      .get("/ingredients/search", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const createIngredient = (
  name: string,
  category: string,
  units: IUnit[],
  proteins: number,
  fats: number,
  carbs: number,
  fiber: number,
  calories: number,
  alco: number,
  brand?: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      name,
      category,
      units,
      brand,
    };
    axiosInstance
      .post("ingredients/create", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const updateIngredient = (
  name: string,
  category: string,
  units: any[],
  proteins: number,
  fats: number,
  carbs: number,
  fiber: number,
  calories: number,
  alco: number,
  id: string,
  brand?: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      name,
      category,
      units,
      ingredient_id: id,
      brand,
    };
    axiosInstance
      .put("ingredients", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const deleteIngredient = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`ingredients/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

// export const updateIngredient = ( name: string,
//   category: string,
//   units: string[],
//   proteins: number,
//   fats: number,
//   carbs: number,
//   fiber: number,
//   calories: number,
//   alco: number)
