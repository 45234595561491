import { FC } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { CheckBigIcon, CrossfitIcon, RowingIcon } from "../../assets";
import { ACTIVITIES } from "../../constants";

const ActivityItem: FC<IProps> = ({ item }): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        {/* <RowingIcon /> */}

        {
          //@ts-ignore
          ACTIVITIES[item.type] || <CrossfitIcon />
        }
        {item?.status && (
          <div className={styles.checkIconWrapper}>
            <CheckBigIcon />
          </div>
        )}
      </div>
      <div className={styles.contentContainer}>
        <span className={styles.title}>{item.type}</span>
        <span className={styles.minutes}>{item.minutes?.toFixed(0)} minutes</span>
      </div>
      <span className={styles.calories}>
        {item.calories?.toLocaleString(undefined, { maximumFractionDigits: 0 })}{" "}
        cal
      </span>
    </div>
  );
};

export default ActivityItem;
