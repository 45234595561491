import React, { ChangeEvent, FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { CrossIcon, RefreshIcon } from "../../assets";
import {
  Button,
  EditableUnit,
  Input,
  Selector,
  TagSelector,
  UnitSelector,
} from "../../components";
import { ingredientsCategories } from "../../constants";
import { getNutrition } from "../../services/ninja.service";
import { useDebounce } from "../../hooks";
import clsx from "clsx";
import {
  createIngredient,
  updateIngredient,
} from "../../services/ingredients.service";
import { toast } from "react-hot-toast";
import axios from "axios";
import { PulseLoader } from "react-spinners";
import { IUnit } from "../../models";

const EditIngredient: FC<IProps> = ({
  isOpen,
  setIsOpen,
  onGetIngredients,
  ingredient,
}): JSX.Element => {
  const [name, setName] = useState(ingredient?.name);
  const [category, setCategory] = useState<string>(ingredient?.category);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [calories, setCalories] = useState<number>(ingredient?.calories);
  const [proteins, setProteins] = useState<number>(ingredient?.proteins);
  const [fats, setFats] = useState<number>(ingredient?.fats);
  const [carbs, setCarbs] = useState<number>(ingredient?.carbs);
  const [fiber, setFiber] = useState<number>(ingredient?.fiber);
  const [alcohol, setAlcohol] = useState<number>(ingredient?.alco);

  const [brand, setBrand] = useState(ingredient?.brand);
  const [addUnitName, setAddUnitName] = useState("");

  const [ingredientUnits, setIngredientUnits] = useState<IUnit[]>(
    ingredient?.units
  );
  useEffect(() => {
    setName(ingredient?.name);
    setCategory(ingredient?.category);
    setCalories(ingredient?.calories);
    setProteins(ingredient?.proteins);
    setFats(ingredient?.fats);
    setCarbs(ingredient?.carbs);
    setFiber(ingredient?.fiber);
    setAlcohol(ingredient?.alco);
    setBrand(ingredient?.brand);
    setIngredientUnits(ingredient?.units?.map((item) => ({ ...item })));
  }, [ingredient, isOpen]);

  const [nameError, setNameError] = useState<string>("");

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onChangeBrand = (e: ChangeEvent<HTMLInputElement>) => {
    setBrand(e.target.value);
  };

  const onUpdateIngredient = async () => {
    try {
      setIsLoadingCreate(true);
      const { data } = await updateIngredient(
        name,
        category,
        ingredientUnits,
        proteins || 0,
        fats || 0,
        carbs || 0,
        fiber || 0,
        calories || 0,
        alcohol || 0,
        ingredient?.id,
        brand
      );
      if (data?.success) {
        toast.success("The ingredient was updated successfully");
        setName("");
        setCalories(0);
        setProteins(0);
        setFats(0);
        setFiber(0);
        setCarbs(0);
        setAlcohol(0);

        setIsOpen(false);
        onGetIngredients();
        setBrand("");
        setNameError("");
        setIngredientUnits([]);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong"
      );
    } finally {
      setIsLoadingCreate(false);
    }
  };

  const onChangeAddUnitName = (e: ChangeEvent<HTMLInputElement>) => {
    setAddUnitName(e.target.value);
  };

  const onAddUnit = (name: string) => {
    if (!addUnitName) {
      return;
    }
    setIngredientUnits((prev) => [
      ...prev,
      {
        name: name,
        grams: 0,
        description: "",
        isDefault: false,
        status: false,
        calories: 0,
        proteins: 0,
        fats: 0,
        carbs: 0,
        fiber: 0,
        alco: 0,
      },
    ]);
    setAddUnitName("");
  };
  const checkIsUnitsValid = () => {
    return ingredientUnits.some((el) => {
      if (el.status && el.isDefault) {
        return true;
      } else {
        return false;
      }
    });
  };
  const isDisabled =
    name?.length < 2 ||
    !category ||
    calories === undefined ||
    proteins === undefined ||
    fats === undefined ||
    carbs === undefined ||
    fiber === undefined ||
    alcohol === undefined ||
    !checkIsUnitsValid();

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <h6>Edit Ingredient</h6>
        <div onClick={() => setIsOpen(false)} className={styles.closeButton}>
          <CrossIcon />
        </div>
        <div className={styles.inputContainer}>
          <Input
            value={name}
            onChange={onChangeName}
            label="Ingredient Name"
            placeholder="Ingredient Name"
            error={nameError}
          />
          <Input
            value={brand}
            onChange={onChangeBrand}
            label="Brand"
            placeholder="Brand"
          />
          <Selector
            value={category}
            setValue={(value) => {
              setCategory(value);
            }}
            data={ingredientsCategories}
            label="Category"
          />
          <span className={styles.sectionTitle}>
            The amount of nutrients must be indicated per 1 unit.
          </span>
          {ingredientUnits?.map((item, index) => (
            <div key={index} className={styles.editableUnitWrapper}>
              <EditableUnit
                index={index}
                item={item}
                setIngredientUnits={setIngredientUnits}
              />
              <div className={styles.editableUnitDivider} />
            </div>
          ))}

          <Input
            placeholder="Unit Name"
            value={addUnitName}
            onChange={onChangeAddUnitName}
            wrapperStyles={styles.addUnitTitle}
          />

          <Button
            onClick={() => {
              onAddUnit(addUnitName);
            }}
            title={"Add Unit"}
            size="x-small"
            styleType="outlined"
            customStyles={styles.addUnitButton}
          />
          <Button
            title="Save"
            size="medium"
            styleType={isDisabled ? "disabled" : "filled"}
            loading={isLoadingCreate}
            onClick={onUpdateIngredient}
            disabled={isDisabled}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditIngredient;
