import React, { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { PulseLoader } from "react-spinners";
import {
  Button,
  CheckBox,
  IngredientItem,
  PaginationButtons,
  SearchInput,
  EditableCategory,
} from "../../../components";
import {
  AddSquareIcon,
  ArrowSmallDownIcon,
  PlaySquareIcon,
} from "../../../assets";
import { getIngredients } from "../../../services/ingredients.service";
import { CreateCategory, CreateUnit } from "../../../modals";
import { useDebounce } from "../../../hooks";
import { getCategories } from "../../../services/category.service";
import { ICategory } from "../../../models";

const Categories: FC = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [categories, setCategories] = useState<ICategory[]>([]);
  const debounceSearch = useDebounce(search, 300);

  const onGetCategories = async () => {
    try {
      setIsLoading(true);
      const { data } = await getCategories(debounceSearch);
      if (data?.success) {
        setCategories(data?.data?.categories);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetCategories();
  }, [debounceSearch]);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <CreateCategory
        isOpen={isOpenCreateModal}
        setIsOpen={setIsOpenCreateModal}
        onGetCategories={onGetCategories}
      />
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <h4 className={styles.title}>Categories</h4>
          <div className={styles.headerAddButton}>
            <Button
              title={"Create New Category"}
              leftIcon={<AddSquareIcon />}
              onClick={() => setIsOpenCreateModal(true)}
              styleType="filled"
              size="small"
            />
          </div>
        </header>
        <section className={styles.contentContainer}>
          <div className={styles.searchContainer}>
            <SearchInput
              placeholder="Find category..."
              value={search}
              onChange={onChangeSearch}
            />
          </div>
          <div className={styles.listContainer}>
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <PulseLoader color={"#7D4AFB"} />
              </div>
            )}
            {!isLoading &&
              categories.map((item, index) => (
                <EditableCategory
                  key={index}
                  title={item.value}
                  onGetCategories={onGetCategories}
                  withCounter
                  counter={item.usage}
                  maxLength={50}
                />
              ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default Categories;
