import React, { ChangeEvent, FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { CrossIcon, RefreshIcon } from "../../assets";
import { Button, Input, Selector, TagSelector } from "../../components";
import { createTags } from "../../services/recipe.service";
import axios from "axios";
import toast from "react-hot-toast";

const CreateTag: FC<IProps> = ({
  isOpen,
  setIsOpen,
  onGetTags,
}): JSX.Element => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  useEffect(() => {
    setName("");
    setNameError("");
  }, [isOpen]);

  const onCreateTag = async () => {
    try {
      setIsLoading(true);
      const { data } = await createTags(name.trim());
      if (data?.success) {
        setName("");
        setIsOpen(false);
        onGetTags();
        setNameError("");
        toast.success("Tag was successfully created");
      } else {
        setNameError("This tag already exists");
      }
    } catch (error) {
      setNameError(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <h6>Create New Tag</h6>
        <div onClick={() => setIsOpen(false)} className={styles.closeButton}>
          <CrossIcon />
        </div>
        <div className={styles.inputContainer}>
          <Input
            value={name}
            onChange={onChangeName}
            placeholder="Tag"
            error={nameError}
            maxLength={30}
          />
        </div>

        <Button
          title="Create"
          size="medium"
          styleType={name.length < 2 ? "disabled" : "filled"}
          disabled={name.length < 2}
          loading={isLoading}
          onClick={onCreateTag}
        />
      </div>
    </Modal>
  );
};

export default CreateTag;
