import { FC } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { ArrowSmallDownIcon } from "../../../../../assets";
import { GroupItem } from "../../../../../components";

const ActiveGroup: FC<IProps> = (): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.sectionHeader}>
        <div
          onClick={() => {}}
          className={clsx(styles.nameBlock, {
            [styles.activeSortValue]: false,
          })}
        >
          <p>User</p>{" "}
          <ArrowSmallDownIcon
            className={clsx(styles.sectionHeaderArrow, {
              [styles.sectionHeaderArrowUp]: false,
            })}
          />
        </div>

        <div
          onClick={() => {}}
          className={clsx(styles.weekBlock, {
            [styles.activeSortValue]: false,
          })}
        >
          <p>Week</p>{" "}
          <ArrowSmallDownIcon
            className={clsx(styles.sectionHeaderArrow, {
              [styles.sectionHeaderArrowUp]: false,
            })}
          />
        </div>
        <div
          onClick={() => {}}
          className={clsx(styles.memberBlock, {
            [styles.activeSortValue]: false,
          })}
        >
          <p>Members</p>{" "}
          <ArrowSmallDownIcon
            className={clsx(styles.sectionHeaderArrow, {
              [styles.sectionHeaderArrowUp]: false,
            })}
          />
        </div>

        <div onClick={() => {}} className={clsx(styles.dateBlock, {})}>
          <p>Registration date</p> <ArrowSmallDownIcon />
        </div>
      </div>
      <div className={styles.itemsList}>
        {[1, 2, 3].map((item, index) => (
          <GroupItem key={index} />
        ))}
      </div>
    </div>
  );
};

export default ActiveGroup;
