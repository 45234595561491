import jwt_decode from "jwt-decode";
import { getMeInfo } from "../services/auth.service";

export const setAccessToken = (token: string) => {
  localStorage.setItem("accessToken", token);
  let decoded = jwt_decode(token);
  setUser(decoded);
};

export const removeAccessToken = () => {
  localStorage.removeItem("accessToken");
};

export const getAccessToken = (): string | null => {
  return localStorage.getItem("accessToken");
};

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem("refreshToken", refreshToken);
};

export const removeRefreshToken = () => {
  localStorage.removeItem("refreshToken");
};

export const getRefreshToken = (): string | null => {
  return localStorage.getItem("refreshToken");
};

export const setUser = (user: any) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const removeUser = () => {
  localStorage.removeItem("user");
};

export const getUser = () => {
  const user = localStorage.getItem("user");
  if (user === null || user === undefined) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export const fetchMe = async () => {
  try {
    const { data } = await getMeInfo();
    if (data?.success) {
      setUser(data?.data);
      window.dispatchEvent(new Event("storage"));
    }
  } catch (error) {}
};
