import React, { FC } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { CheckBigIcon, CheckIcon } from "../../assets";
import ActivityItem from "../ActivityItem";

const MealPlanActivityItem: FC<IProps> = ({
  isComplete,
  activities,
}): JSX.Element => {
  return (
    <div className={clsx(styles.wrapper, {})}>
      <div className={styles.contentContainer}>
        <h4 className={styles.title}>Activities</h4>
        <div className={styles.recipesContainer}>
          {activities?.map((item, index) => (
            <ActivityItem item={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MealPlanActivityItem;
