import { ChangeEvent, FC, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { CrossIcon } from "../../assets";
import { Button, Radio, TextArea } from "../../components";
import { BlockReason } from "../../constants";
import { blockUser } from "../../services/users.service";
// import { blockCandidate } from "../../services/candidate.service";
// import { blockEmployer } from "../../services/employer.service";
import styles from "./styles.module.scss";
import { toast } from "react-hot-toast";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  userId: string;
  onGetUserInfo: () => void;
}

const BlockUser: FC<Props> = ({
  isOpen,
  setIsOpen,
  userId,
  onGetUserInfo,
}): JSX.Element => {
  const [blockReason, setBlockReason] = useState<string>("Other");
  const [otherReason, setOtherReason] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const onChangeOtherReason = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReason(e.target.value);
  };

  const onBlockAccount = async () => {
    try {
      setIsLoading(true);
      const { data } = await blockUser(
        userId,
        blockReason === "Other" ? otherReason : blockReason
      );
      if (data?.success) {
        toast.success("User was successfully blocked");
        // navigate("/companies");
        setOtherReason("");
        onGetUserInfo();
        setIsOpen(false);
      }

      setIsLoading(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong"
      );
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <CrossIcon
          onClick={() => setIsOpen(false)}
          className={styles.closeBtn}
        />
        <h5>Block user</h5>
        <div className={styles.optionsContainer}>
          <div className={styles.blockReasonWrapper}>
            <p className={styles.title}>
              Choose a reason why you want to block the user:
            </p>
            <div className={styles.reasonList}>
              {BlockReason.map((item, index) => {
                return (
                  <div key={index} className={styles.radioOption}>
                    <Radio
                      isCheked={blockReason === item}
                      setIsCheked={() => setBlockReason(item)}
                    />
                    <p className={styles.radioTitle}>{item}</p>
                  </div>
                );
              })}
              {blockReason === "Other" && (
                <TextArea
                  placeholder="Message"
                  value={otherReason}
                  onChange={onChangeOtherReason}
                  maxLength={240}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <Button
            title={"Block"}
            size={"small"}
            loading={isLoading}
            onClick={onBlockAccount}
            disabled={blockReason === "Other" && !otherReason}
            backgroundColor="#FF3C3C"
            styleType={
              blockReason === "Other" && !otherReason ? "disabled" : "filled"
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default BlockUser;
