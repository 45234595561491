import { ChangeEvent, FC, useEffect, useState } from "react";
import { AddSquareIcon, ArrowSmallDownIcon } from "../../../assets";
import { Button, SearchInput, StaffItem } from "../../../components";
import { AddNewStaff } from "../../../modals";
import { IStaff } from "../../../models";
import { getAllStaff } from "../../../services/staff.service";
import styles from "./styles.module.scss";
import PulseLoader from "react-spinners/PulseLoader";
import { getUser } from "../../../utils/tokenStorage";
import clsx from "clsx";

const StaffManagement: FC = (): JSX.Element => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [staff, setStaff] = useState<IStaff[]>([]);
  const [searchedStaff, setSearchedStaff] = useState<IStaff[]>([]);
  const [nameSort, setNameSort] = useState<-1 | 1 | 0>(0);
  const [emailSort, setEmailSort] = useState<-1 | 1 | 0>(0);
  const [typeSort, setTypeSort] = useState<-1 | 1 | 0>(0);

  const me = getUser();
  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetAllStaff = async () => {
    try {
      setIsLoading(true);
      const { data } = await getAllStaff(
        nameSort !== 0
          ? "fullName"
          : emailSort !== 0
          ? "email"
          : typeSort !== 0
          ? "type"
          : null,

        nameSort !== 0
          ? nameSort
          : emailSort !== 0
          ? emailSort
          : typeSort !== 0
          ? typeSort
          : null
      );
      if (data?.success) {
        setStaff(data?.data?.admins);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (search) {
      const filteredStaff = staff.filter((item) => {
        if (item.fullName.toLowerCase().includes(search.toLowerCase())) {
          return item;
        }
      });
      setSearchedStaff(filteredStaff);
    }
  }, [search, staff]);

  useEffect(() => {
    onGetAllStaff();
  }, [nameSort, emailSort, typeSort]);

  if (me?.type !== "Admin") {
    return <></>;
  }

  return (
    <>
      <AddNewStaff
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        onGetAllStaff={onGetAllStaff}
      />
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <h4 className={styles.title}>Staff Management</h4>
        </header>
        <section className={styles.contentContainer}>
          <div className={styles.searchContainer}>
            <div className={styles.searchWrapper}>
              <SearchInput
                placeholder="Search"
                value={search}
                onChange={onChangeSearch}
              />
            </div>

            <Button
              leftIcon={<AddSquareIcon />}
              title={"Add New"}
              size={"small"}
              styleType={"filled"}
              style={{ width: "160px" }}
              onClick={() => setIsOpenModal(true)}
            />
          </div>
          <div className={styles.sectionHeader}>
            <div
              onClick={() => {
                setEmailSort(0);
                setTypeSort(0);
                setNameSort((prev) => {
                  if (prev === 0) {
                    return -1;
                  } else if (prev === -1) {
                    return 1;
                  } else return 0;
                });
              }}
              className={clsx(styles.userBlock, {
                [styles.activeSortValue]: nameSort !== 0,
              })}
            >
              <p>User</p>{" "}
              <ArrowSmallDownIcon
                className={clsx(styles.sectionHeaderArrow, {
                  [styles.sectionHeaderArrowUp]: nameSort === -1,
                })}
              />
            </div>
            <div
              onClick={() => {
                setNameSort(0);
                setTypeSort(0);
                setEmailSort((prev) => {
                  if (prev === 0) {
                    return -1;
                  } else if (prev === -1) {
                    return 1;
                  } else return 0;
                });
              }}
              className={clsx(styles.emailBlock, {
                [styles.activeSortValue]: emailSort !== 0,
              })}
            >
              <p>Email</p>{" "}
              <ArrowSmallDownIcon
                className={clsx(styles.sectionHeaderArrow, {
                  [styles.sectionHeaderArrowUp]: emailSort === -1,
                })}
              />
            </div>
            <div
              onClick={() => {
                setNameSort(0);
                setEmailSort(0);
                setTypeSort((prev) => {
                  if (prev === 0) {
                    return -1;
                  } else if (prev === -1) {
                    return 1;
                  } else return 0;
                });
              }}
              className={clsx(styles.roleBlock, {
                [styles.activeSortValue]: typeSort !== 0,
              })}
            >
              <p>Role</p>{" "}
              <ArrowSmallDownIcon
                className={clsx(styles.sectionHeaderArrow, {
                  [styles.sectionHeaderArrowUp]: typeSort === -1,
                })}
              />
            </div>
          </div>
          <div className={styles.staffContainer}>
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <PulseLoader color={"#7D4AFB"} />
              </div>
            )}
            {!isLoading && search
              ? searchedStaff.map((item, index) => (
                  <StaffItem
                    key={item.id}
                    item={item}
                    onGetAllStaff={onGetAllStaff}
                  />
                ))
              : !isLoading &&
                staff.map((item, index) => (
                  <StaffItem
                    item={item}
                    key={item?.id}
                    onGetAllStaff={onGetAllStaff}
                  />
                ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default StaffManagement;
