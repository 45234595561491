import { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Logo from "../../assets/icons/logo.svg";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { login } from "../../services/auth.service";
import {
  getAccessToken,
  setAccessToken,
  setRefreshToken,
} from "../../utils/tokenStorage";

const SignIn: FC = (): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isRemember, setIsRemember] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");

  useEffect(() => {
    if (email?.length > 3 && password) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [email, password]);

  const navigate = useNavigate();

  useEffect(() => {
    let token = getAccessToken();
    if (token) {
      navigate("/");
    }
  }, []);

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onLogin = async () => {
    try {
      setIsLoading(true);
      const { data } = await login(email.trim(), password.trim());
      if (data?.success) {
        setAccessToken(data?.data?.accessToken);
        setRefreshToken(data?.data?.refreshToken);
        navigate("/statistics");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setEmailError(error?.message);
      setPasswordError(error?.message);
    }
  };

  return (
    <div className={styles.wrapper}>
      <img src={Logo} alt={"Karizma"} />
      <form autoComplete="off" className={styles.signInForm}>
        <Input
          value={email}
          onChange={onChangeEmail}
          label={"Email"}
          placeholder={"example@gmail.com"}
          wrapperStyles={styles.input}
          borderError={emailError}
          autoComplete={"false"}
        />
        <Input
          value={password}
          autoComplete={"new-password"}
          onChange={onChangePassword}
          label={"Password"}
          placeholder={"Password"}
          type={"password"}
          wrapperStyles={styles.input}
          error={passwordError}
        />
        {/* <div className={styles.checkboxContainer}>
          <CheckBox isChecked={isRemember} setIsChecked={setIsRemember} />
          <span>Remember me</span>
        </div> */}
        <Button
          disabled={isDisabled}
          loading={isLoading}
          onClick={onLogin}
          title={"Login"}
          styleType={isDisabled ? "disabled" : "filled"}
          size={"small"}
        />
      </form>
    </div>
  );
};

export default SignIn;
