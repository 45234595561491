import { FC } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import {
  ArrowSmallDownIcon,
  FirstIcon,
  SecondIcon,
  ThirdIcon,
} from "../../assets";
import PlaceholderAvatar from "../../assets/images/avatar.png";

const LeaderBoardItem: FC<IProps> = ({ index }): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.nameBlock}>
        {index === 0 ? (
          <FirstIcon />
        ) : index === 1 ? (
          <SecondIcon />
        ) : index === 2 ? (
          <ThirdIcon />
        ) : (
          <span className={styles.index}>{index}</span>
        )}
        <img className={styles.avatar} src={PlaceholderAvatar} alt={"avatar"} />
        <div className={styles.contentContainer}>
          <span className={styles.name}>Test test</span>
          <span className={styles.years}>27 y.o.</span>
        </div>
      </div>
      <div className={styles.genderBlock}>
        <span className={styles.genderTitle}>Male</span>
      </div>
      <div className={styles.deficitBlock}>
        <span className={styles.deficitTitle}>5,567 cal</span>
        <div className={styles.arrowButton}>
          <ArrowSmallDownIcon />
        </div>
      </div>
    </div>
  );
};

export default LeaderBoardItem;
