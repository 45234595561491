import axiosInstance from "../apis";
import { AxiosResponse } from "axios";

export const getUsers = (
  value: string,
  page: number,
  limit: number,
  gender: string[],
  sortValue: string | null,
  order: number | null
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params: any = {
      value,
      page,
      limit,
    };

    if (gender) {
      params.gender = gender.join(",");
    }

    if (sortValue) {
      params.sortValue = sortValue;
    }

    if (order) {
      params.order = order;
    }
    axiosInstance("users/search", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserById = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`users/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserLogs = (
  id: string,
  from: string,
  to: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params = {
      from,
      to,
    };
    axiosInstance
      .get(`users/${id}/logs`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserDayStats = (
  id: string,
  date: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params = {
      date,
    };
    axiosInstance
      .get(`users/${id}/stats/day`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserWeeklyStats = (
  id: string,
  date: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params = {
      date,
    };
    axiosInstance
      .get(`users/${id}/stats/week`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserMonthStats = (
  id: string,
  date: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params = {
      date,
    };
    axiosInstance
      .get(`users/${id}/stats/month`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserHalfYearStats = (
  id: string,
  date: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params = {
      date,
    };
    axiosInstance
      .get(`users/${id}/stats/half`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserYearStats = (
  id: string,
  date: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params = {
      date,
    };
    axiosInstance
      .get(`users/${id}/stats/year`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserConsistenctStreak = (
  id: string,
  date: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params = {
      date,
    };

    axiosInstance
      .get(`users/${id}/stats/streak`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const deleteUser = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`users/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const blockUser = (
  uid: string,
  blockReason: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      uid,
      blockReason,
    };
    axiosInstance
      .post("users/block", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const unBlockUser = (uid: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      uid,
    };
    axiosInstance
      .post("users/unblock", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
