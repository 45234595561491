import React, { FC, InputHTMLAttributes } from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  wrapperStyles?: any;
}

const TextArea: FC<Props> = ({
  label = "",
  error,
  wrapperStyles = {},
  ...props
}) => {
  return (
    <div className={clsx(styles.input, wrapperStyles)}>
      {label && <span>{label}</span>}
      <textarea
        className={clsx(styles.input__main, {
          [styles.error]: error,
        })}
        {...props}
      />
      {error && <p>{error}</p>}
    </div>
  );
};

export default TextArea;
