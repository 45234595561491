import React, {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  WheelEventHandler,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { InfoCircleIcon, TriangleIcon } from "../../assets";
import { useOutsideClick } from "../../hooks";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  borderError?: string;
  wrapperStyles?: any;
  type?: string;
  withInfoIcon?: boolean;
  infoIconText?: string;
}

const Input: FC<Props> = ({
  label = "",
  error,
  wrapperStyles = {},
  type = "text",
  withInfoIcon = false,
  infoIconText = "",
  borderError,
  ...props
}) => {
  const [inputType, setInputType] = useState(type);
  const [isShowInfoPopUp, setIsShowInfoPopUp] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => setIsShowInfoPopUp(false));

  return (
    <div className={clsx(styles.input, wrapperStyles)}>
      <div className={styles.labelWrapper}>
        {label && <span>{label}</span>}
        {withInfoIcon && (
          <div ref={ref} className={styles.iconWrapper}>
            <InfoCircleIcon
              onClick={() => setIsShowInfoPopUp((prev) => !prev)}
              className={styles.infoIcon}
            />
            {isShowInfoPopUp && (
              <div className={styles.popUpContainer}>
                <div className={styles.popUpInner}>
                  <span>{infoIconText}</span>
                </div>
                <TriangleIcon className={styles.triangle} />
              </div>
            )}
          </div>
        )}
      </div>

      <div className={styles.inputWrapper}>
        <input
          onWheel={(e) => {
            //@ts-ignore
            e.target?.blur();
            e.stopPropagation();
          }}
          className={clsx(styles.input__main, {
            [styles.error]: error || borderError,
            [styles.inputPassword]: type === "password",
          })}
          type={inputType}
          {...props}
        />
        {type === "password" && (
          <div
            className={styles.hideButton}
            onClick={() => {
              setInputType((prev) => (prev === "text" ? "password" : "text"));
            }}
          >
            {inputType === "password" ? "Show" : "Hide"}
          </div>
        )}
      </div>

      {error && <p>{error}</p>}
    </div>
  );
};

export default Input;
