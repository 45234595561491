//react
import React, { useEffect, useState } from "react";

//styles
import styles from "./styles.module.scss";
import { getChartsStatistics } from "../../services/statistics.service";

//libraries
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { PulseLoader } from "react-spinners";

function ControlsBtn({ isActive = false, onClick = () => {}, title = "" }) {
  return (
    <div
      onClick={onClick}
      className={isActive ? styles.activeControlsBtn : styles.contorlsBtn}
    >
      <div
        className={
          isActive ? styles.activeControlsBtnText : styles.controlsBtnText
        }
      >
        {title}
      </div>
    </div>
  );
}

const CustomTooltip = (props) => {
  return (
    <div className={styles.tooltipWrapper}>
      <p className={styles.data}>{props?.payload[0]?.payload?.users}</p>
      <p className={styles.label}>{props?.label}</p>
    </div>
  );
};

function Chart() {
  const [activeType, setActiveType] = useState(1);
  const [focusBar, setFocusBar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [barGraphData, setBarGraphData] = useState({});
  const [toolTipYPosition, setToolTipYPosition] = useState({});

  const onGetChartsStatistics = async () => {
    try {
      setIsLoading(true);
      const { data } = await getChartsStatistics(
        activeType === 0 ? "week" : activeType === 1 ? "month" : "year"
      );
      if (data?.success) {
        let days = data?.data?.days || data?.data?.months;

        let array = days?.map((item) => ({
          name: data?.data?.days ? item?.date?.slice(5, 10) : item?.date,
          users: item?.count,
        }));

        setData(array);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetChartsStatistics();
  }, [activeType]);

  // name pv
  const controls = [{ title: "Week" }, { title: "Month" }, { title: "Year" }];

  return (
    <div className={styles.wrapper}>
      <div className={styles.chartsControls}>
        {controls.map((item, index) => (
          <ControlsBtn
            isActive={index === activeType}
            onClick={() => setActiveType(index)}
            title={item.title}
            key={index}
          />
        ))}
      </div>
      {isLoading ? (
        <div className={styles.loaderWrapper}>
          <PulseLoader color={"#7D4AFB"} />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={240}>
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 30,
              bottom: 5,
            }}
            barSize={20}
            onMouseMove={(state) => {
              if (state.isTooltipActive) {
                setFocusBar(state.activeTooltipIndex);
              } else {
                setFocusBar(null);
              }
            }}
          >
            <XAxis
              dataKey="name"
              padding={{ left: 10, right: 10 }}
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 10 }}
              allowDuplicatedCategory={true}
              allowDataOverflow
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tickMargin={15}
              tick={{ fontSize: 12 }}
            />
            <Tooltip
              content={<CustomTooltip />}
              cursor={false}
              offset={-33.5}
              allowEscapeViewBox={{ x: true, y: true }}
              position={{ y: -toolTipYPosition + 150 }}
              wrapperStyle={{ outline: "none" }}
            />

            <Bar
              dataKey="users"
              fill="#5444EB"
              background={false}
              barSize={26}
              radius={6}
              allowDuplicatedCategory={true}
              isAnimationActive={true}
              label={(e) => {
                if (e.index === focusBar) {
                  setToolTipYPosition(e.height);
                }
                return null;
              }}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  stroke={"none"}
                  fill={focusBar === index ? "#7B61FF" : "#D6D2F8"}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

export default Chart;
