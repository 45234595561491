import React, { FC } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { CheckBox } from "..";
import { SmallCheckedIcon } from "../../assets";
import clsx from "clsx";

const TagSelector: FC<IProps> = ({
  title,
  isChecked,
  setIsChecked,
  count = "",
}): JSX.Element => {
  return (
    <div
      onClick={() => setIsChecked(!isChecked)}
      className={clsx({
        [styles.uncheckedWrapper]: !isChecked,
        [styles.checkedWrapper]: isChecked,
      })}
    >
      {isChecked ? (
        <div className={styles.checked}>
          <SmallCheckedIcon />
        </div>
      ) : (
        <div className={styles.unchecked} />
      )}
      <span
        className={clsx({
          [styles.uncheckedTitle]: !isChecked,
          [styles.checkedTitle]: isChecked,
        })}
      >
        {title}
        {count && <span style={{ color: "yellow" }}> - {count}</span>}
      </span>
    </div>
  );
};

export default TagSelector;
