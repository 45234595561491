import { FC, useState, useRef } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import {
  DotsVertical,
  GroupIcon,
  LikeLinearIcon,
  MessageIcon,
  TrashIcon,
  UserIcon,
} from "../../assets";
import { motion } from "framer-motion";
import { useOutsideClick } from "../../hooks";
import Image from "../../assets/images/blockchain/img.png";

import { UserPost } from "../../modals";

const PostItem: FC<IProps> = (): JSX.Element => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [isShowPostModal, setIsShowPostModal] = useState<boolean>(false);
  const ref = useRef(null);
  useOutsideClick(ref, () => setIsOpenMenu(false));

  return (
    <>
      <UserPost isOpen={isShowPostModal} setIsOpen={setIsShowPostModal} />
      <div
        ref={ref}
        onClick={() => setIsShowPostModal((prev) => !prev)}
        className={styles.wrapper}
      >
        <div className={styles.nameBlock}>
          {/* <img src={Image} className={styles.imageWrapper} /> */}
          <div className={styles.imageWrapper}>
            <GroupIcon />
          </div>
          <div className={styles.contentContainer}>
            <span className={styles.date}>Nov 23, 2022</span>
            <span className={styles.description}>
              I’m trying to workout as best as I can. What do you guys think
              about it?
            </span>
          </div>
        </div>
        <div className={styles.dateBlock}>
          <div className={styles.dateItem}>
            <LikeLinearIcon />
            <span>10</span>
          </div>
          <div className={styles.dateItem}>
            <MessageIcon />
            <span>10</span>
          </div>
          <DotsVertical
            className={styles.dotButton}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpenMenu((prev) => !prev);
            }}
          />
        </div>
        {isOpenMenu && (
          <motion.div
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
            className={styles.menuWrapper}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                setIsOpenMenu(false);
              }}
              className={styles.menuButton}
            >
              <TrashIcon className={styles.deleteIcon} width={20} height={20} />
              <span style={{ color: "#EE3758" }}>Delete</span>
            </div>
          </motion.div>
        )}
      </div>
    </>
  );
};

export default PostItem;
