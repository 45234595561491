import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import {
  Button,
  LeaderboardFilter,
  PaginationButtons,
  SearchInput,
  UserFilter,
  UserItem,
} from "../../../components";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { ArrowSmallDownIcon, FilterIcon } from "../../../assets";

import { useDebounce, useOutsideClick } from "../../../hooks";
import { PulseLoader } from "react-spinners";
import LeaderBoardItem from "../../../components/LeaderBoardItem";

const usersLimit = 20;

const Users: FC = (): JSX.Element => {
  const [search, setSearch] = useState("");
  const filterRef = useRef<HTMLDivElement>(null);
  const debounceSearch = useDebounce(search, 400);

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagesCount, setPagesCount] = useState(0);
  const [page, setPage] = useState(1);
  const [filterGender, setFilterGender] = useState<any[]>([]);
  const [leaderBoard, setLeaderBoard] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [activeTab, setActiveTab] = useState<"Men" | "Women">("Men");
  useOutsideClick(filterRef, () => setIsOpenFilter(false));

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  useEffect(() => {}, [page, debounceSearch, filterGender]);

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <h4 className={styles.title}>Leaderboard</h4>
        <div className={styles.headerAddButton}>
          {/* <Button
          onClick={() => navigation("/")}
          title={"Create New Meal"}
          leftIcon={<AddSquareIcon />}
          styleType="filled"
          size="small"
        /> */}
        </div>
      </header>

      <section className={styles.contentContainer}>
        <div className={styles.headerButtonsContainer}>
          <div
            onClick={() => setActiveTab("Men")}
            className={clsx(styles.tabButton, {
              [styles.tabButtonActive]: activeTab === "Men",
            })}
          >
            <span
              className={clsx(styles.tabButtonTitle, {
                [styles.tabButtonTitleActive]: activeTab === "Men",
              })}
            >
              Men
            </span>
            <div
              className={clsx(styles.tabButtonCounterWrapper, {
                [styles.tabButtonCounterWrapperActive]: activeTab === "Men",
              })}
            >
              <span>0</span>
            </div>
          </div>
          <div
            onClick={() => setActiveTab("Women")}
            className={clsx(styles.tabButton, {
              [styles.tabButtonActive]: activeTab === "Women",
            })}
          >
            <span
              className={clsx(styles.tabButtonTitle, {
                [styles.tabButtonTitleActive]: activeTab === "Women",
              })}
            >
              Women
            </span>
            <div
              className={clsx(styles.tabButtonCounterWrapper, {
                [styles.tabButtonCounterWrapperActive]: activeTab === "Women",
              })}
            >
              <span>0</span>
            </div>
          </div>
        </div>
        <div className={styles.contentContainerHeader}>
          <div className={styles.searchContainer}>
            <SearchInput
              placeholder="Search"
              value={search}
              onChange={onChangeSearch}
            />
          </div>
          <div ref={filterRef}>
            <div
              onClick={() => setIsOpenFilter((prev) => !prev)}
              className={clsx(styles.filterButton, {
                [styles.activeFilterButton]: isOpenFilter,
              })}
            >
              <FilterIcon />
            </div>
            {isOpenFilter && <LeaderboardFilter setIsOpen={setIsOpenFilter} />}
          </div>
        </div>

        <div className={styles.sectionHeader}>
          <div className={styles.nameBlock}>
            <p>User</p> <ArrowSmallDownIcon />
          </div>
          <div className={styles.genderBlock}>
            <p>Gender</p> <ArrowSmallDownIcon />
          </div>
          <div className={styles.deficitBlock}>
            <p>kcal/week</p> <ArrowSmallDownIcon />
          </div>
        </div>

        <div className={styles.listContainer}>
          {isLoading ? (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#7D4AFB"} />
            </div>
          ) : (
            leaderBoard.map((item, index) => (
              <LeaderBoardItem key={index} index={index} />
            ))
          )}
        </div>
        <PaginationButtons
          pagesCount={pagesCount}
          page={page}
          onPress={(index) => setPage(index)}
        />
      </section>
    </div>
  );
};

export default Users;
