import { FC, useState, useRef, useEffect } from "react";
import { IProps } from "./types";
import Modal from "react-modal";
import styles from "./styles.module.scss";
import { ArrowDownIcon, ArrowSmallDownIcon, GroupIcon } from "../../assets";
import { motion } from "framer-motion";
import clsx from "clsx";
import { LeaderboardChart } from "../../components";

const TABS = ["Deficit", "Weight", "Consistency"];

const Group: FC<IProps> = ({ isOpen, setIsOpen }): JSX.Element => {
  const [tabIndicatorOffset, setTabIndicatorOffset] = useState(0);
  const [tabIndicatorWidth, setTabIndicatorWidth] = useState(0);
  const [activeTab, setActiveTab] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current?.offsetWidth) {
      let indicatorWidth = ref.current.offsetWidth / TABS.length;

      setTabIndicatorWidth(indicatorWidth);
      setTabIndicatorOffset(indicatorWidth * activeTab);
    }
  }, [ref.current, activeTab]);

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <div className={styles.imageBackground}>
          <GroupIcon />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.dateSwitcher}>
            <div className={styles.dateSwitcherLeftButton}>
              <ArrowDownIcon />
            </div>
            <div className={styles.dateContainer}>
              <span className={styles.weekTitle}>Week 5</span>
              <span className={styles.dateTitle}>Feb 12 - Feb 18, 2022</span>
            </div>
            <div className={styles.dateSwitcherRightButton}>
              <ArrowDownIcon />
            </div>
          </div>
          <div ref={ref} className={styles.tabContainer}>
            {TABS.map((item, index) => (
              <div
                onClick={() => setActiveTab(index)}
                key={index}
                className={clsx(styles.tabButton, {
                  [styles.tabButtonActive]: activeTab === index,
                })}
              >
                {item}
              </div>
            ))}
            <motion.div
              animate={{ x: tabIndicatorOffset }}
              transition={{ type: "keyframes" }}
              style={{ width: tabIndicatorWidth }}
              className={styles.tabIndicator}
            />
          </div>
          <LeaderboardChart />
        </div>
      </div>
    </Modal>
  );
};

export default Group;
