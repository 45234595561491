import React, { ChangeEvent, FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { CrossIcon, RefreshIcon } from "../../assets";
import {
  AddUnit,
  Button,
  EditableUnit,
  Input,
  Selector,
  TagSelector,
  UnitSelector,
} from "../../components";
import { defaultIngredientsUnit, ingredientsCategories } from "../../constants";
import { getNutrition } from "../../services/ninja.service";
import { useDebounce } from "../../hooks";
import clsx from "clsx";
import { createIngredient } from "../../services/ingredients.service";
import { toast } from "react-hot-toast";
import axios from "axios";
import { PulseLoader } from "react-spinners";
import { IUnit } from "../../models";

const CreateIngredient: FC<IProps> = ({
  isOpen,
  setIsOpen,
  onGetIngredients,
}): JSX.Element => {
  const [name, setName] = useState("");
  const debounceName = useDebounce(name, 500);

  const [category, setCategory] = useState<string>(ingredientsCategories[0]);
  const [isLoadingNutrition, setIsLoadingNutrition] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [calories, setCalories] = useState<number>(0);
  const [proteins, setProteins] = useState<number>(0);
  const [fats, setFats] = useState<number>(0);
  const [carbs, setCarbs] = useState<number>(0);
  const [fiber, setFiber] = useState<number>(0);
  const [alcohol, setAlcohol] = useState<number>(0);

  const [defaultUnits, setDefaultUnits] = useState<IUnit[]>(
    defaultIngredientsUnit.map((item) => ({ ...item }))
  );
  const [addUnitName, setAddUnitName] = useState("");

  const [nameError, setNameError] = useState<string>("");
  const [apiName, setApiName] = useState<string>("");
  const [servingSizeForOne, setServingSizeForOne] = useState<number>(0);
  const [isShowAddUnit, setIsShowAddUnit] = useState(false);

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const [brand, setBrand] = useState("");

  const onChangeBrand = (e: ChangeEvent<HTMLInputElement>) => {
    setBrand(e.target.value);
  };

  const onCreateIngredient = async () => {
    try {
      setIsLoadingCreate(true);
      const { data } = await createIngredient(
        name,
        category,
        defaultUnits,
        proteins || 0,
        fats || 0,
        carbs || 0,
        fiber || 0,
        calories || 0,
        alcohol || 0,
        brand
      );
      if (data?.success) {
        toast.success("The ingredient was created successfully");
        setName("");
        setCalories(0);
        setProteins(0);
        setFats(0);
        setFiber(0);
        setCarbs(0);
        setAlcohol(0);
        setIsOpen(false);
        onGetIngredients();
        setNameError("");
        setServingSizeForOne(0);
        setServingSizeForOne(0);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong"
      );
    } finally {
      setIsLoadingCreate(false);
    }
  };

  useEffect(() => {
    setName("");
    setCalories(0);
    setProteins(0);
    setFats(0);
    setFiber(0);
    setCarbs(0);
    setAlcohol(0);
    setNameError("");
    setCategory(ingredientsCategories[0]);
    setServingSizeForOne(0);
    setDefaultUnits(defaultIngredientsUnit);
    setBrand("");
  }, [isOpen]);

  const checkIsUnitsValid = () => {
    return defaultUnits.some((el) => {
      if (el.status && el.isDefault) {
        return true;
      } else {
        return false;
      }
    });
  };
  const isDisabled =
    name.length < 2 ||
    !category ||
    calories === undefined ||
    proteins === undefined ||
    fats === undefined ||
    carbs === undefined ||
    fiber === undefined ||
    alcohol === undefined ||
    servingSizeForOne === undefined ||
    !checkIsUnitsValid();

  const onAddUnit = (name: string) => {
    if (!addUnitName) {
      return;
    }
    setDefaultUnits((prev) => [
      ...prev,
      {
        name: name,
        grams: 0,
        description: "",
        isDefault: false,
        status: false,
        calories: 0,
        proteins: 0,
        fats: 0,
        carbs: 0,
        fiber: 0,
        alco: 0,
      },
    ]);
    setAddUnitName("");
  };

  const onChangeAddUnitName = (e: ChangeEvent<HTMLInputElement>) => {
    setAddUnitName(e.target.value);
  };
  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <h6>Create New Ingredient</h6>
        <div onClick={() => setIsOpen(false)} className={styles.closeButton}>
          <CrossIcon />
        </div>
        <div className={styles.inputContainer}>
          <Input
            value={name}
            onChange={onChangeName}
            label="Ingredient Name"
            placeholder="Ingredient Name"
            error={nameError}
          />
          <Input
            value={brand}
            onChange={onChangeBrand}
            label="Brand"
            placeholder="Brand"
          />

          <Selector
            value={category}
            setValue={(value) => {
              // setSelectedUnits([]);
              setCategory(value);
            }}
            data={ingredientsCategories}
            label="Category"
          />
        </div>
        <p className={styles.sectionTitle}>
          The amount of nutrients must be indicated per 1 unit.
        </p>

        {defaultUnits.map((item, index) => (
          <div key={index} className={styles.editableUnitWrapper}>
            {
              <EditableUnit
                setIngredientUnits={setDefaultUnits}
                index={index}
                item={item}
              />
            }
            <div className={styles.editableUnitDivider} />
          </div>
        ))}

        <Input
          placeholder="Unit Name"
          value={addUnitName}
          onChange={onChangeAddUnitName}
          wrapperStyles={styles.addUnitTitle}
        />

        <Button
          onClick={() => {
            onAddUnit(addUnitName);
          }}
          title={"Add Unit"}
          size="x-small"
          styleType="outlined"
          customStyles={styles.addUnitButton}
        />
        <Button
          title="Create"
          size="medium"
          styleType={isDisabled ? "disabled" : "filled"}
          loading={isLoadingCreate}
          onClick={onCreateIngredient}
          disabled={isDisabled}
        />
      </div>
    </Modal>
  );
};

export default CreateIngredient;
