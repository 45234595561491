import { FC, useState } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import {
  ArrowDownIcon,
  ArrowSmallDownIcon,
  CheckIcon,
  UserIcon,
} from "../../assets";
import { Group } from "../../modals";

const GroupItem: FC<IProps> = (): JSX.Element => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      <Group isOpen={isOpenModal} setIsOpen={setIsOpenModal} />
      <div onClick={() => setIsOpenModal(true)} className={styles.wrapper}>
        <div className={styles.nameBlock}>
          <div className={styles.imageWrapper}>
            <UserIcon />
          </div>
          <span className={styles.title}>Weight Loss Buddies</span>
        </div>
        <div className={styles.weekBlock}>Week 6/10 </div>
        <div className={styles.memberBlock}>
          <div className={styles.membersContainer}>
            {[1, 2, 3].map((item, index) => (
              <div
                key={index}
                style={{ zIndex: -index }}
                className={styles.avatar}
              >
                <UserIcon />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.dateBlock}>
          <span className={styles.date}>Feb 20</span>
          <div className={styles.arrowWrapper}>
            <ArrowSmallDownIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupItem;
