import { FC } from "react";
import {
  SignIn,
  Statistics,
  StaffManagement,
  AllRecipes,
  Ingredients,
  Units,
  Categories,
  Tags,
  CreateRecipe,
  EditRecipe,
  Users,
  UserProfile,
  Reports,
  LeaderBoard,
} from "../pages";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

const Navigation: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="signIn" element={<SignIn />} />
        <Route path="/*" element={<PrivateRoute />}>
          <Route index path="statistics" element={<Statistics />} />
          <Route path="staff-management" element={<StaffManagement />} />
          <Route path="all-recipes" element={<AllRecipes />} />
          <Route path="create-recipe" element={<CreateRecipe />} />
          <Route path="edit-recipe/:id" element={<EditRecipe />} />
          <Route path="ingredients" element={<Ingredients />} />
          <Route path="units" element={<Units />} />
          <Route path="categories" element={<Categories />} />
          <Route path="tags" element={<Tags />} />
          <Route path="users" element={<Users />} />
          <Route path="users/:id" element={<UserProfile />} />
          <Route path="reports" element={<Reports />} />
          <Route path="leaderboard" element={<LeaderBoard />} />
          <Route path="*" element={<Navigate to="/statistics" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
