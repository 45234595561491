import React, { FC } from "react";
import styles from "./styles.module.scss";
import { ArrowSmallDownIcon, UserIcon } from "../../assets";
import { IProps } from "./types";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import dayjs from "dayjs";

const UserItem: FC<IProps> = ({ item }): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/users/${item?.id}`)}
      className={styles.wrapper}
    >
      <div className={styles.nameBlock}>
        <div className={styles.avatarWrapper}>
          {item?.image ? (
            <img src={item?.image} className={styles.avatar} />
          ) : (
            <UserIcon />
          )}
        </div>
        <span className={styles.name}>
          {item?.firstName} {item?.lastName}
        </span>
      </div>
      <div className={styles.genderBlock}>
        <span className={styles.gender}>{item?.gender}</span>
      </div>
      <div className={styles.deficitBlock}>
        <span className={styles.deficit}>
          {item?.email}
          {/* {item?.currentWeight} {item?.weightUnit} */}
        </span>
      </div>
      <div className={styles.dateBlock}>
        <span className={styles.date}>
          {dayjs.utc(item?.onboardingAt).format("MMM DD, YYYY")}
        </span>
        <div className={styles.arrowButton}>
          <ArrowSmallDownIcon />
        </div>
      </div>
    </div>
  );
};

export default UserItem;
