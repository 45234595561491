import { FC } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { CheckBigIcon, RecipeIcon } from "../../assets";

const MealPlanRecipeItem: FC<IProps> = ({ item }): JSX.Element => {
  const macro = {
    cal: item?.calories?.toLocaleString(undefined, {
      maximumFractionDigits: 0,
    }),
    Protein: item?.proteins?.toLocaleString(undefined, {
      maximumFractionDigits: 0,
    }),
    Fat: item?.fats?.toLocaleString(undefined, {
      maximumFractionDigits: 0,
    }),
    Carbs: item?.carbs?.toLocaleString(undefined, {
      maximumFractionDigits: 0,
    }),
    Fiber: item?.fiber?.toLocaleString(undefined, {
      maximumFractionDigits: 0,
    }),
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.imageWrapper}>
        {item?.image ? (
          <img src={item.image} className={styles.image} />
        ) : (
          <RecipeIcon />
        )}
        {item?.status && (
          <div className={styles.checkIconWrapper}>
            <CheckBigIcon />
          </div>
        )}
      </div>
      <div className={styles.contentContainer}>
        <span className={styles.title}>
          {item.name} {item.count > 1 && `(${item.count})`}
        </span>
        <div className={styles.macroContainer}>
          {Object.entries(macro).map((item, index) => (
            <div className={styles.macroItem}>
              <span className={styles.macroValue}>
                {item[1]} {item[0] !== "cal" && "g"}
              </span>
              <span className={styles.macroTitle}>{item[0]}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MealPlanRecipeItem;
