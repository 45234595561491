import { FC, useState } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowDownIcon } from "../../assets";
import { CheckBox } from "..";
import clsx from "clsx";
import { INGREDIENT_CATEGORIES } from "../../constants";

interface ITags {
  value: string;
  usage: number;
}

const IngredientsFilter: FC<IProps> = ({
  filterSelectedCategories,
  setFilterSelectedCategories,
  setIsOpen,
}): JSX.Element => {
  const [isShowCategoryList, setIsShowCategoryList] = useState(false);

  return (
    <motion.div
      initial={{ scale: 0.8, opacity: 0.5 }}
      animate={{ scale: 1, opacity: 1 }}
      className={styles.wrapper}
    >
      <h5 className={styles.title}>Filter</h5>
      <div className={styles.sectionDevider} />
      <div
        onClick={() => setIsShowCategoryList((prev) => !prev)}
        className={styles.sectionHeader}
      >
        <span className={styles.sectionTitle}>Categories</span>
        <ArrowDownIcon
          className={clsx({
            [styles.arrowOpen]: isShowCategoryList,
            [styles.arrowClosed]: !isShowCategoryList,
          })}
        />
      </div>
      <AnimatePresence>
        {isShowCategoryList && (
          <motion.div
            initial={{ height: 0, marginTop: 0 }}
            animate={{ height: 250, marginTop: 10 }}
            exit={{ height: 0, marginTop: 0 }}
            className={styles.listContainer}
          >
            {Object.keys(INGREDIENT_CATEGORIES).map((item, index) => {
              const isChecked = filterSelectedCategories.includes(item);
              const onSelect = () => {
                if (isChecked) {
                  setFilterSelectedCategories(
                    filterSelectedCategories.filter(
                      (category) => category !== item
                    )
                  );
                } else {
                  setFilterSelectedCategories([
                    ...filterSelectedCategories,
                    item,
                  ]);
                }
              };

              return (
                <div key={index} className={styles.selectorItem}>
                  <CheckBox isChecked={isChecked} setIsChecked={onSelect} />
                  <span
                    style={{ color: isChecked ? "#11142D" : "#808191" }}
                    className={styles.selectorItemText}
                  >
                    {item}
                  </span>
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>

      <div className={styles.sectionDevider} />

      <div
        onClick={() => {
          setFilterSelectedCategories([]);
        }}
        className={styles.resetButton}
      >
        <div>Reset all filters</div>
      </div>
    </motion.div>
  );
};

export default IngredientsFilter;
