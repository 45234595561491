import { FC } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { TrophyIcon, UserIcon } from "../../assets";

const LeaderboardChart: FC<IProps> = (): JSX.Element => {
  const maxValue = 0;

  const generateYAxisValues = () => {
    let step = (maxValue + 600) / 4;
    return Array(5)
      .fill("")
      .map((_, index) => step * index);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.yAxisContainer}>
        {generateYAxisValues().map((item, index) => (
          <div key={index} className={styles.yAxisText}>
            {item}
          </div>
        ))}
      </div>
      <div className={styles.xAxisContainer}>
        {Array(4)
          .fill("")
          .map((item, index) => (
            <div key={index} className={styles.xAxisItem}>
              <div className={styles.avatar}>
                <UserIcon />
              </div>
              <span className={styles.name}>James</span>
            </div>
          ))}
      </div>

      <div className={styles.gridContainer}>
        <div className={styles.gridLine} />
        <div className={styles.gridLine} />
        <div className={styles.gridLine} />
        <div className={styles.gridLine} />
        <div className={styles.gridLine} />
      </div>
      <div className={styles.chartWrapper}>
        {Array(4)
          .fill("")
          .map((item, index) => {
            return (
              <div className={styles.barItemWrapper}>
                {index === 3 && <TrophyIcon className={styles.trophyIcon} />}
                <div
                  style={{
                    height: `${50 + index * 10}%`,
                    backgroundColor: index === 3 ? "#36D90E" : "#612AFF",
                  }}
                  className={styles.barItem}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default LeaderboardChart;
