import React, { FC } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { INGREDIENT_CATEGORIES } from "../../constants";
import { IConfigurableIngredient } from "../../pages/Dashboard/CreateRecipe/types";

const RecipeIngredient: FC<IProps> = ({ item }): JSX.Element => {
  const macro = {
    cal: item?.calories,
    Protein: item?.proteins,
    Fat: item?.fats,
    Carbs: item?.carbs,
    Fiber: item?.fiber,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        {
          //@ts-ignore
          INGREDIENT_CATEGORIES[item?.category]
        }
      </div>
      <div className={styles.contentContainer}>
        <span className={styles.title}>
          {item.name}{" "}
          {item?.brand ? (
            <span className={styles.brandName}>· {item.brand} </span>
          ) : (
            " "
          )}
          <span className={styles.unit}>
            {item.count} {item.unit}
          </span>
          {item.unit === "serving" && (
            <span style={{ fontWeight: 400 }} className={styles.unit}>
              {" "}
              ({item.units?.find((el) => el.name === item.unit)?.description})
            </span>
          )}
        </span>
        <div className={styles.macroContainer}>
          {Object.entries(macro).map((item, index) => (
            <div key={index} className={styles.macroItem}>
              <span
                style={{ color: item[1] === 0 ? "#BBC9D7" : "#1E2632" }}
                className={styles.macroValue}
              >
                {item[1]?.toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                })}
                {item[0] !== "cal" ? " g" : ""}
              </span>
              <span
                style={{ color: item[1] === 0 ? "#BBC9D7" : "#8896AA" }}
                className={styles.macroTitle}
              >
                {item[0]}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecipeIngredient;
