import { FC, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import {
  AlcoIcon,
  ArrowSmallDownIcon,
  CalorieIcon,
  CarbsIcon,
  FatsIcon,
  FiberIcon,
  PeIcon,
  ProteinsIcon,
} from "../../assets";
import { getDaysOfWeek } from "../../utils";
import { add, format } from "date-fns";
import DayItem from "../DayItem";
import MacroIconItem from "../MacroIconItem";
import MealPlanItem from "../MealPlanItem";
import MealPlanActivityItem from "../MealPlanActivityItem";
import { IProps } from "./types";
import { IMealLogs } from "../../models";
import { getUserLogs } from "../../services/users.service";
import { PulseLoader } from "react-spinners";
import dayjs from "dayjs";

const MealPlanCalendar: FC<IProps> = ({ userInfo }): JSX.Element => {
  const [dateOffset, setDateOffset] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [mealPlan, setMealPlan] = useState<IMealLogs[]>();
  const [isLoadingMealPlan, setIsLoadingMealPlan] = useState(false);
  const [daysInWeek, setDaysInWeek] = useState<string[]>(
    getDaysOfWeek(dateOffset)
  );

  const [selectedDayInMealPlan, setSelectedDayInMealPlan] =
    useState<IMealLogs>();

  useEffect(() => {
    const index = mealPlan?.findIndex(
      (item) => item.date === selectedDate?.toISOString().split("T")[0]
    );

    if (index !== -1 && mealPlan && typeof index === "number") {
      setSelectedDayInMealPlan(mealPlan[index]);
    }
  }, [mealPlan, selectedDate]);

  const onGetMealPlan = async () => {
    try {
      setIsLoadingMealPlan(true);
      const { data } = await getUserLogs(
        userInfo?.id,
        daysInWeek[0],
        daysInWeek[6]
      );
      if (data?.success) {
        setMealPlan(data?.data?.mealLogs);
      }
    } catch (error) {
    } finally {
      setIsLoadingMealPlan(false);
    }
  };

  useEffect(() => {
    onGetMealPlan();
  }, [daysInWeek]);

  const onPrevWeek = () => {
    setDateOffset((prev) => add(prev, { weeks: -1 }));
  };

  const onNextWeek = () => {
    setDateOffset((prev) => add(prev, { weeks: 1 }));
  };

  const macro = useMemo(
    () => ({
      cal: selectedDayInMealPlan
        ? selectedDayInMealPlan?.calories?.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })
        : 0,
      Protein: selectedDayInMealPlan
        ? selectedDayInMealPlan?.proteins?.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })
        : 0,
      Fat: selectedDayInMealPlan
        ? selectedDayInMealPlan?.fats?.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })
        : 0,
      Carbs: selectedDayInMealPlan
        ? selectedDayInMealPlan?.carbs?.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })
        : 0,
      Fiber: selectedDayInMealPlan
        ? selectedDayInMealPlan?.fiber?.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })
        : 0,
      Alco:
        selectedDayInMealPlan && selectedDayInMealPlan?.alco
          ? selectedDayInMealPlan?.alco?.toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })
          : 0,
      Pe: selectedDayInMealPlan
        ? selectedDayInMealPlan?.pe?.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })
        : 0,
    }),
    [selectedDayInMealPlan]
  );
  useEffect(() => {
    setDaysInWeek(getDaysOfWeek(dateOffset));
  }, [dateOffset]);

  if (isLoadingMealPlan) {
    return (
      <div className={styles.loaderContainer}>
        <PulseLoader color={"#612aff"} />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <h5 className={styles.title}>Meal Plan</h5>
      <div className={styles.dateHeader}>
        <div onClick={onPrevWeek} className={styles.dateHeaderButton}>
          <ArrowSmallDownIcon className={styles.leftArrow} />
        </div>
        <p className={styles.dateTitle}>
          {format(new Date(daysInWeek[0]), "MMM dd")} -{" "}
          {format(new Date(daysInWeek[6]), "MMM dd, yyyy")}
        </p>
        <div onClick={onNextWeek} className={styles.dateHeaderButton}>
          <ArrowSmallDownIcon className={styles.rightArrow} />
        </div>
      </div>
      <div className={styles.daysList}>
        {daysInWeek.map((item, index) => {
          const dayIndexInMealPlan = mealPlan?.findIndex(
            (meal) => meal.date === item
          );

          let isCompleted = false;

          if (
            dayIndexInMealPlan !== -1 &&
            mealPlan &&
            typeof dayIndexInMealPlan === "number"
          ) {
            isCompleted = mealPlan[dayIndexInMealPlan].status;
          }

          return (
            <DayItem
              key={index}
              date={dayjs.utc(item).toDate()}
              isCompleted={isCompleted}
              isDisabled={dayIndexInMealPlan === -1}
              onSelect={() => setSelectedDate(dayjs.utc(item).toDate())}
              isSelected={item === selectedDate?.toISOString()?.split("T")[0]}
            />
          );
        })}
      </div>
      <div className={styles.macroContainer}>
        <MacroIconItem
          icon={<CalorieIcon style={{ fill: "#36D90E" }} />}
          iconBackgroundCOlor="rgba(54, 217, 14, 0.2)"
          iconColor="#36D90E"
          title="cal"
          value={`${macro.cal} g`}
        />
        <MacroIconItem
          icon={<ProteinsIcon style={{ fill: "#4AC8FB" }} />}
          iconBackgroundCOlor="rgba(74, 200, 251, 0.2)"
          iconColor="#4AC8FB"
          title="Protein"
          value={`${macro.Protein} g`}
        />
        <MacroIconItem
          icon={<FatsIcon style={{ fill: "#FFA800" }} />}
          iconBackgroundCOlor="rgba(255, 168, 0, 0.2)"
          iconColor="#FFA800"
          title="Fat"
          value={`${macro.Fat} g`}
        />
        <MacroIconItem
          icon={<CarbsIcon style={{ fill: "#E478F5" }} />}
          iconBackgroundCOlor="rgba(228, 120, 245, 0.2)"
          iconColor="#E478F5"
          title="Carbs"
          value={`${macro.Carbs} g`}
        />
        <MacroIconItem
          icon={<FiberIcon style={{ fill: "#01E3AD" }} />}
          iconBackgroundCOlor="rgba(1, 227, 173, 0.2)"
          iconColor="#01E3AD"
          title="Fiber"
          value={`${macro.Fiber} g`}
        />
        <MacroIconItem
          icon={<AlcoIcon style={{ fill: "#C17834" }} />}
          iconBackgroundCOlor="rgba(193, 120, 52, 0.2)"
          iconColor="#C17834"
          title="Alco"
          value={`${macro.Alco} g`}
        />
        <MacroIconItem
          icon={<PeIcon />}
          iconBackgroundCOlor="transparent"
          iconColor="#C17834"
          title="P/E"
          value={`${macro.Pe} g`}
        />
      </div>
      <div className={styles.mealSections}>
        {selectedDayInMealPlan?.sections.map((item, index) => {
          return (
            <MealPlanItem
              key={index}
              title={item?.name}
              isComplete={!!selectedDayInMealPlan?.status}
              item={item}
              completeType={selectedDayInMealPlan?.completeType}
            />
          );
        })}
        {!!selectedDayInMealPlan?.activities?.length && (
          <MealPlanActivityItem
            isComplete={!!selectedDayInMealPlan?.status}
            activities={selectedDayInMealPlan?.activities}
          />
        )}
      </div>
    </div>
  );
};

export default MealPlanCalendar;
