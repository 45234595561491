import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

export const getCategories = (search: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params = {
      value: search,
    };

    axiosInstance
      .get("recipes/categories", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const createCategory = (value: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      value,
    };

    axiosInstance
      .post("recipes/categories", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const updateCategory = (
  oldValue: string,
  newValue: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      oldValue,
      newValue,
    };

    axiosInstance
      .put("recipes/categories", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const deleteCategory = (category: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`recipes/categories/${category}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
