import axiosInstance from "../apis";
import axios from "axios";
import { AxiosResponse } from "axios";
import fileDownload from "js-file-download";

export const uploadFile = (
  file: any,
  uploadUrl: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(uploadUrl, file, {
        headers: {
          "Content-Type": "application/octet-stream",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const downloadFile = (url: string, filename: string) => {
  axios
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, filename);
    });
};
