import React, { ChangeEvent, FC, useState } from "react";
import Modal from "react-modal";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { CrossIcon, RefreshIcon } from "../../assets";
import { Button, Input, Selector, TagSelector } from "../../components";

const CreateUnit: FC<IProps> = ({ isOpen, setIsOpen }): JSX.Element => {
  const [name, setName] = useState("");

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <h6>New Unit</h6>
        <div onClick={() => setIsOpen(false)} className={styles.closeButton}>
          <CrossIcon />
        </div>
        <div className={styles.inputContainer}>
          <Input value={name} onChange={onChangeName} placeholder="Unit name" />
        </div>

        <Button title="Create" size="medium" styleType="filled" />
      </div>
    </Modal>
  );
};

export default CreateUnit;
