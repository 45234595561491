import { FC, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { IProps } from "./types";
import { ArrowSmallDownIcon, ArrowSmallLineUpIcon } from "../../assets";
import { add, format } from "date-fns";
import { motion } from "framer-motion";
import { getUserDayStats } from "../../services/users.service";
import { PulseLoader } from "react-spinners";
import clsx from "clsx";

const DailyChart: FC<IProps> = ({ userInfo }): JSX.Element => {
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [lineWidth, setLineWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const chartContainerRef = useRef<HTMLDivElement>(null);

  const onGetDailyData = async () => {
    try {
      setIsLoading(true);
      const { data } = await getUserDayStats(
        userInfo.id,
        format(selectedDate, "yyyy-MM-dd")
      );
      if (data?.success) {
        setData(data?.data);
      } else {
        setData(undefined);
      }
    } catch (error) {
      setData(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetDailyData();
  }, [userInfo, selectedDate]);

  useEffect(() => {
    if (ref.current?.offsetWidth) {
      setLineWidth((ref.current.offsetWidth - 35) / 3 - 80);
    }
  }, [chartContainerRef.current]);

  useEffect(() => {
    setIndicatorWidth((ref.current?.offsetWidth || 0) / 2);
  }, [ref.current]);

  const onPrevDay = () => {
    setSelectedDate(add(selectedDate, { days: -1 }));
  };

  const onNextDay = () => {
    setSelectedDate(add(selectedDate, { days: 1 }));
  };

  const maxValue = useMemo(() => {
    let maxValue = 0;
    if (data) {
      [data?.BMR + data?.activitiesCalories, data?.food].forEach((item) => {
        if (item > maxValue) {
          maxValue = item;
        }
      });
      return maxValue;
    }
    return 0;
  }, [data]);

  const caloriesInHeight = useMemo(() => {
    if (!data?.food) {
      return 0;
    }
    return (data?.food / (maxValue + 600)) * 100;
  }, [data]);

  const caloriesBmrHeight = useMemo(() => {
    if (!data?.BMR) {
      return 0;
    }
    return (data?.BMR / (maxValue + 600)) * 100;
  }, [data]);

  const caloriesWorkoutHeight = useMemo(() => {
    if (!data?.activitiesCalories) {
      return 0;
    }
    return (data?.activitiesCalories / (maxValue + 600)) * 100;
  }, [data]);

  const caloriesOutHeight = useMemo(() => {
    return caloriesBmrHeight + caloriesWorkoutHeight;
  }, [caloriesBmrHeight, caloriesWorkoutHeight]);

  const deficitMarginBottom = useMemo(() => {
    if (!data || !caloriesInHeight || !caloriesBmrHeight) {
      return 0;
    }

    let minimumHeight =
      caloriesInHeight < caloriesOutHeight
        ? caloriesInHeight
        : caloriesOutHeight;

    return (223 * minimumHeight) / 100;
  }, [data, caloriesInHeight, caloriesOutHeight]);

  const deficitHeight = useMemo(() => {
    if (!data) {
      return 0;
    }
    return (Math.abs(data?.deficit) / (maxValue + 600)) * 100;
  }, [data]);

  const targetDeficitHeight = useMemo(() => {
    if (!data) {
      return 0;
    }
    if (Math.abs(data?.deficit) > Math.abs(data?.target)) {
      return (Math.abs(data?.target) / Math.abs(data?.deficit)) * 100;
    }
    return 100;
  }, [data]);

  const superDeficitHeight = useMemo(() => {
    if (!data) {
      return 0;
    }

    let superDeficit = 0;
    if (Math.abs(data?.deficit) > Math.abs(data?.target) && data?.deficit < 0) {
      superDeficit = Math.abs(data?.deficit) - Math.abs(data?.target);
    }

    if (superDeficit > 0) {
      return (Math.abs(superDeficit) / Math.abs(data?.deficit)) * 100;
    }

    return 0;
  }, [data]);

  const generateYAxisValues = () => {
    let step = (maxValue + 600) / 5;
    return Array(6)
      .fill("")
      .map((_, index) => step * index);
  };

  return (
    <div ref={ref} className={styles.wrapper}>
      <div className={styles.dateHeader}>
        <div onClick={onPrevDay} className={styles.dateHeaderButton}>
          <ArrowSmallDownIcon className={styles.leftArrow} />
        </div>
        <p className={styles.dateTitle}>
          {format(selectedDate, "MMM dd, yyyy")}
        </p>
        <div onClick={onNextDay} className={styles.dateHeaderButton}>
          <ArrowSmallDownIcon className={styles.rightArrow} />
        </div>
      </div>
      {isLoading ? (
        <div className={styles.loaderWrapper}>
          <PulseLoader size={10} color={"#612aff"} />
        </div>
      ) : (
        <>
          <div className={styles.rangeSwitcher}>
            <div className={styles.rangeButton}>
              <span className={styles.rangeButtonValue}>
                {data?.deficit > 0 && "+"}
                {Math.abs(data?.deficit || 0)?.toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                }) || 0}{" "}
                <span className={styles.rangeButtonUnit}>cal</span>
                {!!data?.percents && (
                  <div
                    className={clsx(styles.percentsContainer, {
                      [styles.percentsContainerRed]: data?.percents < 0,
                    })}
                  >
                    <ArrowSmallLineUpIcon
                      className={clsx(styles.arrow, {
                        [styles.arrowRef]: data?.percents < 0,
                      })}
                    />
                    <span className={styles.percentValue}>
                      {Math.abs(data?.percents)}%
                    </span>
                  </div>
                )}
              </span>

              <span className={styles.rangeButtontitle}>DAILY DEFICIT</span>
            </div>
            <div className={styles.rangeButton} />
            <motion.div
              style={{ width: indicatorWidth }}
              className={styles.rangeIndicator}
            />
          </div>
          <div className={styles.chartWrapper}>
            <div className={styles.yAxisContainer}>
              {generateYAxisValues().map((item, index) => (
                <div key={index} className={styles.yAxisText}>{item.toFixed(0)}</div>
              ))}
            </div>
            <div className={styles.gridContainer}>
              <div className={styles.gridLine} />
              <div className={styles.gridLine} />
              <div className={styles.gridLine} />
              <div className={styles.gridLine} />
              <div className={styles.gridLine} />
              <div className={styles.gridLine} />
            </div>
            <div className={styles.xAxisContainer}>
              <div className={styles.xAxisText}>Calories In</div>
              <div className={styles.xAxisText} />
              <div className={styles.xAxisText}>Calories Out</div>
            </div>
            <div ref={chartContainerRef} className={styles.chartContainer}>
              <div className={styles.barContainer}>
                {caloriesInHeight > 0 && (
                  <div
                    style={{ height: `${caloriesInHeight}%` }}
                    className={styles.caloriesInBar}
                  />
                )}
              </div>
              <div className={styles.barContainer}>
                {deficitHeight > 0 && (
                  <div
                    style={{
                      marginBottom: deficitMarginBottom,
                      height: `${deficitHeight}%`,
                    }}
                    className={styles.deficitBar}
                  >
                    <div
                      style={{
                        top: data?.deficit > 0 ? 0 : "unset",
                        bottom: data?.deficit < 0 ? 0 : "unset",
                        width: lineWidth,
                        left: -lineWidth,
                        borderColor:
                          data?.deficit > 0
                            ? "rgba(255, 41, 80, 1)"
                            : "rgba(97, 42, 255, 1)",
                      }}
                      className={styles.leftLine}
                    />
                    <div
                      style={{
                        top: data?.deficit > 0 ? "unset" : 0,
                        bottom: data?.deficit > 0 ? 0 : "unset",
                        width: lineWidth,
                        right: -lineWidth,
                        borderColor:
                          data?.deficit > 0
                            ? "rgba(255, 41, 80, 1)"
                            : "rgba(97, 42, 255, 1)",
                      }}
                      className={styles.leftLine}
                    />
                    <div
                      style={{ height: `${superDeficitHeight}%` }}
                      className={styles.superDeficitBar}
                    >
                      <div className={styles.lineWrapper}>
                        {Array(80)
                          .fill("")
                          .map((_, index) => (
                            <div
                              key={index}
                              style={{
                                backgroundColor:
                                  index % 2 === 0
                                    ? "rgba(97, 42, 255, 0.2)"
                                    : "rgba(97, 42, 255, 1)",
                              }}
                              className={styles.line}
                            />
                          ))}
                      </div>
                    </div>
                    <div
                      style={{
                        height:
                          data?.deficit > 0
                            ? "100%"
                            : `${targetDeficitHeight}%`,
                        backgroundColor:
                          data?.deficit > 0
                            ? "rgba(255, 41, 80, 1)"
                            : "rgba(97, 42, 255, 1)",
                        borderWidth: data?.deficit > 0 ? 0 : 3,
                      }}
                      className={styles.targetDeficit}
                    />
                  </div>
                )}
              </div>
              <div className={styles.barContainer}>
                {caloriesWorkoutHeight > 0 && (
                  <div
                    style={{ height: `${caloriesWorkoutHeight}%` }}
                    className={styles.workoutBar}
                  />
                )}
                {caloriesBmrHeight > 0 && (
                  <div
                    style={{
                      height: `${caloriesBmrHeight}%`,
                      borderTopWidth: caloriesWorkoutHeight ? 0 : 3,
                      borderTopLeftRadius: caloriesWorkoutHeight ? 0 : 3,
                      borderTopRightRadius: caloriesWorkoutHeight ? 0 : 3,
                    }}
                    className={styles.caloriesBmrBar}
                  />
                )}
              </div>
            </div>
          </div>

          <div className={styles.footerChart}>
            <div className={styles.footerChartItem}>
              <div
                style={{ backgroundColor: "#612AFF", borderColor: "#612AFF" }}
                className={styles.footerChartItemIndicator}
              />
              <span className={styles.footerChartItemTitle}>Deficit</span>
            </div>

            <div className={styles.footerChartItem}>
              <div
                style={{ backgroundColor: "#FF2950", borderColor: "#FF2950" }}
                className={styles.footerChartItemIndicator}
              />
              <span className={styles.footerChartItemTitle}>Surplus</span>
            </div>

            <div className={styles.footerChartItem}>
              <div
                style={{
                  backgroundColor: "rgba(97, 42, 255, 0.2)",
                  borderColor: "rgba(97, 42, 255, 0.2)",
                }}
                className={styles.footerChartItemIndicator}
              />
              <span className={styles.footerChartItemTitle}>Target</span>
            </div>

            <div className={styles.footerChartItem}>
              <div className={styles.footerChartItemIndicatorSuperDeficit}>
                <div className={styles.primary} />
                <div className={styles.opacity} />
              </div>
              <span className={styles.footerChartItemTitle}>Super deficit</span>
            </div>

            <div className={styles.footerChartItem}>
              <div
                style={{
                  backgroundColor: "rgba(74, 200, 251, 0.2)",
                  borderColor: "#4AC8FB",
                }}
                className={styles.footerChartItemIndicator}
              />
              <span className={styles.footerChartItemTitle}>Food</span>
            </div>

            <div className={styles.footerChartItem}>
              <div
                style={{
                  backgroundColor: "rgba(255, 168, 0, 0.2)",
                  borderColor: "#FFA800",
                }}
                className={styles.footerChartItemIndicator}
              />
              <span className={styles.footerChartItemTitle}>Metabolism</span>
            </div>

            <div className={styles.footerChartItem}>
              <div
                style={{
                  backgroundColor: "rgba(1, 227, 173, 0.2)",
                  borderColor: "#01E3AD",
                }}
                className={styles.footerChartItemIndicator}
              />
              <span className={styles.footerChartItemTitle}>Activity</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DailyChart;
