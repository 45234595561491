import { FC, useState } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { CheckBox, Input } from "..";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowDownIcon } from "../../assets";
import clsx from "clsx";

const EditableUnit: FC<IProps> = ({
  item,
  setIngredientUnits,
  index,
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const macro = {
    cal: item.calories,
    Protein: item.proteins,
    Fat: item.fats,
    Carbs: item.carbs,
    Fiber: item.fiber,
  };

  const onChangeUnitCalories = (index: number, calories: string) => {
    setIngredientUnits((prev) => {
      const copy = prev.map((item) => ({ ...item }));
      copy[index].calories = calories ? parseFloat(calories) : "";
      return copy;
    });
  };

  const onChangeUnitProteins = (index: number, proteins: string) => {
    setIngredientUnits((prev) => {
      const copy = prev.map((item) => ({ ...item }));
      copy[index].proteins = proteins ? parseFloat(proteins) : "";
      return copy;
    });
  };

  const onChangeUnitFats = (index: number, fats: string) => {
    setIngredientUnits((prev) => {
      const copy = prev.map((item) => ({ ...item }));
      copy[index].fats = fats ? parseFloat(fats) : "";
      return copy;
    });
  };

  const onChangeUnitCarbs = (index: number, carbs: string) => {
    setIngredientUnits((prev) => {
      const copy = prev.map((item) => ({ ...item }));
      copy[index].carbs = carbs ? parseFloat(carbs) : "";
      return copy;
    });
  };

  const onChangeUnitFiber = (index: number, fiber: string) => {
    setIngredientUnits((prev) => {
      const copy = prev.map((item) => ({ ...item }));
      copy[index].fiber = fiber ? parseFloat(fiber) : "";
      return copy;
    });
  };

  const onChangeUnitAlco = (index: number, alco: string) => {
    setIngredientUnits((prev) => {
      const copy = prev.map((item) => ({ ...item }));
      copy[index].alco = alco ? parseFloat(alco) : "";
      return copy;
    });
  };

  const onChangeUnitDescription = (index: number, description: string) => {
    setIngredientUnits((prev) => {
      const copy = prev.map((item) => ({ ...item }));
      copy[index].description = description;
      return copy;
    });
  };

  const onChangeStatus = (index: number, status: boolean) => {
    setIngredientUnits((prev) => {
      const copy = prev.map((item) => ({ ...item }));
      copy[index].status = status;
      return copy;
    });
    if (!status) {
      setIngredientUnits((prev) => {
        const copy = prev.map((item) => ({ ...item }));
        if (copy[index].isDefault) {
          copy[index].isDefault = false;
          const firstUsedUnitIndex = copy.findIndex((el) => el.status);
          if (firstUsedUnitIndex !== -1) {
            copy[firstUsedUnitIndex].isDefault = true;
          }
          return copy;
        } else {
          return copy;
        }
      });
    }
    if (status) {
      setIngredientUnits((prev) => {
        const copy = prev.map((item) => ({ ...item }));
        const isSomeSelectedByDefault = copy.some((el) => el.isDefault);
        if (isSomeSelectedByDefault) {
          return copy;
        } else {
          copy[index].isDefault = true;
          return copy;
        }
      });
    }
  };

  const onChangeIsDefault = (index: number, isDefault: boolean) => {
    if (!item.status) {
      setIngredientUnits((prev) => {
        const copy = prev.map((item) => ({ ...item }));
        copy[index].status = true;
        return copy;
      });
    }
    setIngredientUnits((prev) => {
      const copy = prev.map((item) => ({ ...item }));
      copy.forEach((item) => (item.isDefault = false));
      copy[index].isDefault = isDefault;
      if (!isDefault) {
        const firstUsedUnitIndex = copy.findIndex((el) => el.status);
        if (firstUsedUnitIndex !== -1) {
          copy[firstUsedUnitIndex].isDefault = true;
        }
      }
      return copy;
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.selectorWrapper}>
        <CheckBox
          isChecked={item.status}
          setIsChecked={() => onChangeStatus(index, !item.status)}
        />
        <div
          onClick={() => setIsOpen((prev) => !prev)}
          className={styles.selectorContainer}
        >
          <span className={styles.unitName}>{item?.name}</span>
          <div className={styles.macroContainer}>
            {Object.entries(macro).map((item, index) => (
              <div key={index} className={styles.macroItem}>
                <span className={styles.macroValue}>
                  {item[1]?.toLocaleString(undefined, {
                    maximumFractionDigits: item[0] === "cal" ? 0 : 1,
                  })}
                  {item[0] !== "cal" ? " g" : ""}
                </span>
                <span className={styles.macroTitle}>{item[0]}</span>
              </div>
            ))}
          </div>
          <ArrowDownIcon
            className={clsx(styles.arrowClose, { [styles.arrowOpen]: isOpen })}
          />
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0, marginTop: 0 }}
            exit={{ height: 0, opacity: 0, marginTop: 0 }}
            animate={{ height: 230, opacity: 1, marginTop: 10 }}
            className={styles.editorContainer}
          >
            <div className={styles.inputsContainer}>
              <Input
                label="Calories"
                value={item.calories}
                onChange={(e) => onChangeUnitCalories(index, e.target.value)}
                type="number"
                placeholder="0"
              />
              <Input
                label="Protein"
                value={item.proteins}
                onChange={(e) => onChangeUnitProteins(index, e.target.value)}
                type="number"
                placeholder="0"
              />
              <Input
                label="Fat"
                type="number"
                onChange={(e) => onChangeUnitFats(index, e.target.value)}
                value={item.fats}
                placeholder="0"
              />
              <Input
                label="Carbs"
                type="number"
                onChange={(e) => onChangeUnitCarbs(index, e.target.value)}
                value={item.carbs}
                placeholder="0"
              />
              <Input
                label="Fiber"
                type="number"
                value={item.fiber}
                onChange={(e) => onChangeUnitFiber(index, e.target.value)}
                placeholder="0"
              />
              <Input
                label="Alcohol"
                type="number"
                value={item.alco}
                onChange={(e) => onChangeUnitAlco(index, e.target.value)}
                placeholder="0"
              />
            </div>

            <Input
              label="Description"
              value={item?.description}
              placeholder="Description"
              onChange={(e) => onChangeUnitDescription(index, e.target.value)}
            />
            <div className={styles.defaultContainer}>
              <span>Is default</span>{" "}
              <CheckBox
                isChecked={item.isDefault}
                setIsChecked={() => onChangeIsDefault(index, !item?.isDefault)}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default EditableUnit;
