import { FC } from "react";
import styles from "./styles.module.scss";
import { IProps } from "./types";
import { format } from "date-fns";
import clsx from "clsx";

const DayItem: FC<IProps> = ({
  date,
  isCompleted,
  isSelected,
  onSelect,
  isDisabled,
}): JSX.Element => {
  return (
    <div
      onClick={isDisabled ? () => {} : onSelect}
      className={clsx(styles.wrapper, {
        [styles.wrapperSelected]: isSelected,
        [styles.wrapperDisabled]: isDisabled,
      })}
    >
      <span
        className={clsx(styles.dayTitle, {
          [styles.dayTitleSelected]: isSelected,
        })}
      >
        {format(date, "E")}
      </span>
      <div
        className={clsx(styles.dayNumberWrapper, {
          [styles.dayNumberWrapperSelected]: isSelected,
        })}
      >
        <span
          className={clsx(styles.dayNumber, {
            [styles.dayNumberSelected]: isSelected,
          })}
        >
          {format(date, "dd")}
        </span>
      </div>
      {!isSelected && isCompleted && (
        <div className={styles.completedIndicator} />
      )}
    </div>
  );
};

export default DayItem;
