import React, { FC, useEffect, useState } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowDownIcon, ArrowSmallDownIcon } from "../../assets";
import { Button, CheckBox } from "..";
import clsx from "clsx";
import { getTags } from "../../services/recipe.service";
import { getCategories } from "../../services/category.service";

interface ITags {
  value: string;
  usage: number;
}

const AllRecipesFilter: FC<IProps> = ({
  filterSelectedCategories,
  filterSelectedTags,
  setFilterSelectedCategories,
  setFilterSelectedTags,
  setIsOpen,
}): JSX.Element => {
  const [isShowCategoryList, setIsShowCategoryList] = useState(false);
  const [isShowTagList, setIsShowTagList] = useState(false);
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [tags, setTags] = useState<ITags[]>([]);
  const [categories, setCategories] = useState<ITags[]>([]);

  const onGetTags = async () => {
    try {
      setIsLoadingTags(true);
      const { data } = await getTags("");
      if (data?.success) {
        setTags(data?.data?.tags);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingTags(false);
    }
  };

  const onGetCategories = async () => {
    try {
      setIsLoadingCategories(true);
      const { data } = await getCategories("");
      if (data?.success) {
        setCategories(data?.data?.categories);
      }
    } catch (error) {
    } finally {
      setIsLoadingCategories(false);
    }
  };

  useEffect(() => {
    onGetTags();
    onGetCategories();
  }, []);

  return (
    <motion.div
      initial={{ scale: 0.8, opacity: 0.5 }}
      animate={{ scale: 1, opacity: 1 }}
      className={styles.wrapper}
    >
      <h5 className={styles.title}>Filter</h5>
      <div className={styles.sectionDevider} />
      <div
        onClick={() => setIsShowCategoryList((prev) => !prev)}
        className={styles.sectionHeader}
      >
        <span className={styles.sectionTitle}>Categories</span>
        <ArrowDownIcon
          className={clsx({
            [styles.arrowOpen]: isShowCategoryList,
            [styles.arrowClosed]: !isShowCategoryList,
          })}
        />
      </div>
      <AnimatePresence>
        {isShowCategoryList && (
          <motion.div
            initial={{ height: 0, marginTop: 0 }}
            animate={{ height: 250, marginTop: 10 }}
            exit={{ height: 0, marginTop: 0 }}
            className={styles.listContainer}
          >
            {categories.map((item, index) => {
              const isChecked = filterSelectedCategories.includes(item.value);
              const onSelect = () => {
                if (isChecked) {
                  setFilterSelectedCategories(
                    filterSelectedCategories.filter(
                      (category) => category !== item.value
                    )
                  );
                } else {
                  setFilterSelectedCategories([
                    ...filterSelectedCategories,
                    item.value,
                  ]);
                }
              };

              return (
                <div key={index} className={styles.selectorItem}>
                  <CheckBox isChecked={isChecked} setIsChecked={onSelect} />
                  <span
                    style={{ color: isChecked ? "#11142D" : "#808191" }}
                    className={styles.selectorItemText}
                  >
                    {item.value} ({item?.usage})
                  </span>
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>

      <div className={styles.sectionDevider} />

      <div
        onClick={() => setIsShowTagList((prev) => !prev)}
        className={styles.sectionHeader}
      >
        <span className={styles.sectionTitle}>Tags</span>
        <ArrowDownIcon
          className={clsx({
            [styles.arrowOpen]: isShowTagList,
            [styles.arrowClosed]: !isShowTagList,
          })}
        />
      </div>
      <AnimatePresence>
        {isShowTagList && (
          <motion.div
            initial={{ height: 0, marginTop: 0 }}
            animate={{ height: 250, marginTop: 10 }}
            exit={{ height: 0, marginTop: 0 }}
            className={styles.listContainer}
          >
            {tags.map((item, index) => {
              const isChecked = filterSelectedTags.includes(item.value);
              const onSelect = () => {
                if (isChecked) {
                  setFilterSelectedTags(
                    filterSelectedTags.filter(
                      (category) => category !== item.value
                    )
                  );
                } else {
                  setFilterSelectedTags([...filterSelectedTags, item.value]);
                }
              };

              return (
                <div key={index} className={styles.selectorItem}>
                  <CheckBox isChecked={isChecked} setIsChecked={onSelect} />
                  <span
                    style={{ color: isChecked ? "#11142D" : "#808191" }}
                    className={styles.selectorItemText}
                  >
                    {item.value} ({item?.usage})
                  </span>
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>

      <div className={styles.sectionDevider} />
      {/* <Button
        onClick={() => {
          setIsOpen(false);
        }}
        title="Show recipes"
        size="small"
        styleType="filled"
      /> */}
      <div
        onClick={() => {
          setFilterSelectedCategories([]);
          setFilterSelectedTags([]);
        }}
        className={styles.resetButton}
      >
        <div>Reset all filters</div>
      </div>
    </motion.div>
  );
};

export default AllRecipesFilter;
